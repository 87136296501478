import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Plugin, Title, Tooltip } from "chart.js"
import { FC } from "react"
import { Bar } from "react-chartjs-2"

import { useApp } from "../../app"

import "./index.less"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const BarChart: FC<{
  labels: string[]
  data: number[]
  yTitle?: string
  minTick?: number
  maxTick?: number
  ticks?: any[]
  tickStep?: number
  height?: number
  displayPercentsAndValues?: boolean
  displayPercentsOnly?: boolean
  displayValuesLikePercents?: boolean
}> = ({
  labels,
  data,
  yTitle,
  minTick,
  maxTick,
  ticks,
  tickStep,
  height,
  displayPercentsAndValues = false,
  displayPercentsOnly = false,
  displayValuesLikePercents = false,
  ...props
}) => {
  const { isArab } = useApp()

  const totalSum = data.reduce((acc, value) => acc + value, 0)

  const barData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: "#4480FF",
        barPercentage: 0.4,
      },
    ],
  }

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    layout: {
      padding: isArab ? 30 : 0,
    },
    scales: {
      x: {
        grid: {
          color: "#F3F3F5",
          offset: false,
          drawTicks: false,
        },
        border: { color: "#757A89" },
        ticks: {
          padding: 10,
          font: {
            size: 14,
            family: 'Tajawal, Inter, sans-serif', // Font family
          },
          color: "#757A89",
        },
        reverse: isArab,
      },
      y: {
        grid: {
          color: "#F3F3F5",
        },
        border: {
          display: !isArab,
          dash: [12],
        },
        min: minTick || 0,
        max: maxTick,
        ticks: {
          display: !isArab,
          padding: 15,
          font: {
            size: 14,
            lineHeight: 1.21,
            family: 'Tajawal, Inter, sans-serif', // Font family

          },
          color: "#757A89",
          stepSize: tickStep,
          callback: (value: any) => {
            if (ticks?.includes(value)) {
              return displayValuesLikePercents ? `${value}%` : value
            }
            return displayValuesLikePercents ? `${value}%` : value
          },
        },
      },
    },
  }

  const formatPercentage = (value: number) => {
    const percentage = (value / totalSum) * 100
    return percentage % 1 === 0 ? `${percentage}%` : `${percentage.toFixed(1)}%`
  }

  const valueAndPercentPlugin: Plugin = {
    id: "valueAndPercentPlugin",
    afterDatasetsDraw: chart => {
      const ctx = chart.ctx
      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i)
        meta.data.forEach((bar, index) => {
          const data = dataset.data[index] as number
          const valueText = displayValuesLikePercents ? `${data}%` : data.toString()
          const percentageText = formatPercentage(data)

          ctx.font = "16px Inter"

          ctx.fillStyle = "#FFF"
          const valueY = bar.y + 19
          ctx.fillText(
            valueText,
            isArab ? bar.x + ctx.measureText(valueText).width / 2 : bar.x - ctx.measureText(valueText).width / 2,
            valueY
          )

          ctx.fillStyle = "#4480FF"
          const percentageY = bar.y - 10
          ctx.fillText(
            percentageText,
            isArab
              ? bar.x + ctx.measureText(percentageText).width / 2
              : bar.x - ctx.measureText(percentageText).width / 2,
            percentageY
          )
        })
      })
    },
  }

  const percentOnlyPlugin: Plugin = {
    id: "percentOnlyPlugin",
    afterDatasetsDraw: chart => {
      const ctx = chart.ctx
      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i)
        meta.data.forEach((bar, index) => {
          const data = dataset.data[index] as number
          const percentageText = formatPercentage(data)

          ctx.font = "16px Inter"

          ctx.fillStyle = "#4480FF"
          const percentageY = bar.y - 10
          ctx.fillText(
            percentageText,
            isArab
              ? bar.x + ctx.measureText(percentageText).width / 2
              : bar.x - ctx.measureText(percentageText).width / 2,
            percentageY
          )
        })
      })
    },
  }

  const valuesLikePercentsPlugin: Plugin = {
    id: "valuesLikePercentsPlugin",
    afterDatasetsDraw: chart => {
      const ctx = chart.ctx
      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i)
        meta.data.forEach((bar, index) => {
          const data = dataset.data[index] as number
          const valueText = `${data}%`

          ctx.font = "16px Inter"
          ctx.fillStyle = "#4480FF"

          const valueY = bar.y - 10
          ctx.fillText(
            valueText,
            isArab ? bar.x + ctx.measureText(valueText).width / 2 : bar.x - ctx.measureText(valueText).width / 2,
            valueY
          )
        })
      })
    },
  }

  const rightYTicks: Plugin = {
    id: "rightYTicks",
    afterDatasetsDraw: chart => {
      const {
        ctx,
        scales,
        chartArea: { right },
      } = chart
      scales.y.ticks.map((tick, i) => {
        const label = (tick.label as string) || tick.value
        ctx.font = "14px Inter"
        ctx.fillStyle = "#757A89"
        ctx.fillText(label.toString(), right + 25, scales.y.getPixelForTick(i))
      })
    },
  }

  let plugins = []
  if (displayPercentsAndValues) {
    plugins.push(valueAndPercentPlugin)
  } else if (displayPercentsOnly) {
    plugins.push(percentOnlyPlugin)
  } else if (displayValuesLikePercents) {
    plugins.push(valuesLikePercentsPlugin)
  }

  isArab && plugins.push(rightYTicks)

  return (
    <div style={{ height: height || "auto" }}>
      <Bar data={barData} options={barOptions} plugins={plugins} height={height} {...props} />
    </div>
  )
}
