import { Flex, Row, Typography } from "antd"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { useApp } from "../../app"

import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg"

import { complexFollowersData } from "../../../shared/fake-data/overviewChartsData"

import { StatsCards } from "src/components/ui/Stats/StatsCards"
import { DashboardCardTemplate, OverviewRanking, ProgressBar, Select } from "../../ui"

import { ComplexChartType, Country, DisplayMode, Mode, Role } from "../../../types/enums"

import { PlatformTabLabel } from "../../../types/dataTypes"
import { MultiLineGraph } from "../../charts/MultiLineGraph"
import { PercentageDifference } from "../../ui/PercentageDifference"

import { RankingMinistries } from "../RankingMinistries"
import { getUserProfile } from 'src/helpers/getUserProfile';

import "./OverviewOnePlatformSection.less"

const chartTypes: ComplexChartType[] = [
  ComplexChartType.Followers,
  ComplexChartType.Likes,
  ComplexChartType.Comments,
  ComplexChartType.Posts,
]




const OverviewOnePlatformSection: FC<{
  tab: string
  mode: Mode
  displayMode: DisplayMode | null
  tabContentKey: PlatformTabLabel
  data: any
}> = ({ tab, mode, data, displayMode, tabContentKey }) => {
  const { isArab, role,country } = useApp()
  const { t, i18n } = useTranslation()
  const [chartType, setChartType] = useState(chartTypes[0])


  //! CHANGE
  const user = i18n.language === 'en' ? getUserProfile().title_en : getUserProfile().title_ar


  const number = 12.12


  const isMinistries = displayMode === DisplayMode.Ministries || mode === Mode.All
  const isMinistriesAndOrganizations =
    (displayMode === DisplayMode.MinistriesAndOrganizations || mode === Mode.All) &&
    role !== Role.Ministry &&
    role !== Role.PrimeMinister
  const isArabWorld = displayMode === DisplayMode.ArabWorld || mode === Mode.All
  const localIndex = role !== Role.PrimeMinister? data.local_match_profile : data.local_all_profile
  


  const topLocalPositionsData = data.local_all_profile.map((it: {
    p_avatar: any; overall_rank: any; profile: any;
    profile_short: any;
  }) => {
   

    return {
      src: it.p_avatar,
      title: it.overall_rank,
      description: it.profile,
      uniqueId: it.profile + it.profile_short
    }
  }).map((item:any,index:any) =>{
    item.ranking = index + 1
    return  item
  })

  
  const withOrgsIndex = data.ministry_organization_match_profile
  const topWithOrgsPositionsData = data.ministry_organization_all_profile.map(
    (it: { p_avatar: any; overall_rank: any; profile:any
      profile_short: any;
     }) => {
       

      
      return {
        src: it.p_avatar,
        title: it.overall_rank,
        description: it.profile,
        uniqueId: it.profile + it.profile_short
      }
    }).map((item:any,index:any) =>{
      item.ranking = index + 1
      return  item
    })


  const arabWorldIndex = data.arab_world_match_profile
  const topArabWorldPositionsData = data.arab_world_all_profile.map(
    (it: { p_avatar: any; overall_rank: any; profile: any; 
      profile_short: any;
     }) => {
    
      return {
        src: it.p_avatar,
        title: it.overall_rank,
        description: it.profile,
        uniqueId: it.profile + it.profile_short
      }
    }).map((item:any,index:any) =>{
      item.ranking = index + 1
      return  item
    })

  const chartDataset =
    tabContentKey === "Instagram"
      ? {
        label: "Instagram",
        borderColor: "#6048F3",
        data:
          chartType === ComplexChartType.Followers
            ? data.instagram_profile.followers_series
            : chartType === ComplexChartType.Likes
              ? data.instagram_profile.likes_series
              : chartType === ComplexChartType.Comments
                ? data.instagram_profile.comments_series
                : data.instagram_profile.posts_series,
      }
      : tabContentKey === "Youtube"
        ? {
          label: "Youtube",
          borderColor: "#DA1818",
          data:
            chartType === ComplexChartType.Followers
              ? data.youtube_profile.followers_series
              : chartType === ComplexChartType.Likes
                ? data.youtube_profile.likes_series
                : chartType === ComplexChartType.Comments
                  ? data.youtube_profile.comments_series
                  : [],
        }
        : tabContentKey === "Facebook" ?{
          label: "Facebook",
          borderColor: "#4480FF",
          data:
            chartType === ComplexChartType.Followers
              ? data.facebook_profile.followers_series
              : chartType === ComplexChartType.Likes
                ? data.facebook_profile.likes_series
                : chartType === ComplexChartType.Comments
                  ? data.facebook_profile.comments_series
                  : [],
        }
        : {
          label: "X",
          borderColor: "#757A89",
          data:
            chartType === ComplexChartType.Followers
              ? data.twitter_profile.followers_series
              : chartType === ComplexChartType.Likes
                ? data.twitter_profile.likes_series
                : chartType === ComplexChartType.Comments
                  ? data.twitter_profile.comments_series
                  : data.twitter_profile.posts_series,
        }

  

  const chartData = {
    labels: complexFollowersData.labels,
    borderColor: "#219AFF",
    datasets: chartDataset ? [chartDataset] : [],
  }

  const engagementData =
    tabContentKey === "Instagram"
      ? data.instagram_profile.day_engagement_series.map((item: string, index: number) => {
        return {
          label: data.instagram_profile.day_label_series[index],
          value: item,
        }
      })
      : tabContentKey === "Facebook"
        ? data.facebook_profile.day_engagement_series.map((item: string, index: number) => {
          return {
            label: data.facebook_profile.day_label_series[index],
            value: item,
          }
        })
        :  tabContentKey === "X"?
        data.twitter_profile.day_engagement_series.map((item: string, index: number) => {
          return {
            label: data.twitter_profile.day_label_series[index],
            value: item,
          }
        }) : []

  const statsCardsData =
    tabContentKey === "Instagram"
      ? [
        {
          title: "Followers",
          title_ar: "متابعين",
          number: +data.instagram_profile.followers_stat,
          gainedNumber: +data.instagram_profile.followers_gained_stat,
        },
        {
          title: "Likes",
          title_ar: "إعجابات",
          number: +data.instagram_profile.likes_stat,
        },
        {
          title: "Comments",
          title_ar: "تعليقات",
          number: +data.instagram_profile.comments_stat,
        },
        {
          title: "Posts",
          title_ar: "منشورات",
          number: +data.instagram_profile.posts_stat,
        },
      ]
      : tabContentKey === "Youtube"
        ? [
          {
            title: "Followers",
            title_ar: "متابعين",
              number: +data.youtube_profile.followers_stat,
            gainedNumber: +data.youtube_profile.followers_gained_stat,
          },
          {
            title: "Likes",
            title_ar: "إعجابات",
              number: +data.youtube_profile.likes_stat,
          },
          {
            title: "Comments",
            title_ar: "تعليقات",
              number: +data.youtube_profile.comments_stat,
          },
        ]
        : tabContentKey === "X"? [
          {
            title: "Followers",
            title_ar: "متابعين",
              number: +data.twitter_profile.followers_stat,
            gainedNumber: +data.twitter_profile.followers_gained_stat,
          },
          {
            title: "Likes",
            title_ar: "إعجابات",
              number: +data.twitter_profile.likes_stat,
          },
          {
            title: "retweets",
            title_ar: "إعادة تغريدات",
  
            number: +data.twitter_profile.comments_stat,
          },
          {
            title: "Posts",
            title_ar: "منشورات",
            number: +data.twitter_profile.posts_stat,
          },
        ]: [
          {
            title: "Followers",
            title_ar: "متابعين",
              number: +data.facebook_profile.followers_stat,
            gainedNumber: +data.facebook_profile.followers_gained_stat,
          },
          {
            title: "Likes",
            title_ar: "إعجابات",
              number: +data.facebook_profile.likes_stat,
          },
          {
            title: "Comments",
            title_ar: "تعليقات",
            number: +data.facebook_profile.comments_stat,
          },
          {
            title: "Posts",
            title_ar: "منشورات",
            number: +data.facebook_profile.posts_stat,
          },
        ]

  const getEvaluation = (data: any) => {
    switch (tab) {
      case "All platforms": {
        return data.overall_rank_score
      }
      case "Facebook": {
        return data.Facebook_total_score
      }
      case "Instagram": {
        return data.Instagram_total_score
      }
      case "Youtube": {
        return data.Youtube_total_score
      }
      case "X": {
        return data.Twitter_total_score
      }
      default: {
        return "Unknown"
      }
    }
  }

  return (
    <Flex vertical gap={24}>
      {Role.Ministry &&
        (   localIndex === null || localIndex.length === 0 ? <></> :
          role === Role.Ministry? (
          <OverviewRanking
            tab={tab}
            user={localIndex.profile + localIndex.profile_short}
            title={localIndex.profile}
            country={localIndex.country_name}
            mode={DisplayMode.Ministries}
            platformsRanking={{
              facebook: localIndex.Facebook_rank,
              instagram: localIndex.Instagram_rank,
              youtube: localIndex.Youtube_rank,
              x: localIndex.Twitter_rank,
            }}
            place={localIndex.overall_rank}
            totalPlaces={data.local_profile_stat}
            evaluation={getEvaluation(localIndex)}
            topPositions={topLocalPositionsData}
          />
        ) : 
        Role.Organization ? (
          // Placeholder or empty fragment
          <></>
        ):
        (
         
         
          <RankingMinistries
              tagText={DisplayMode.Ministries}
              user={user}
              ministriesData={data?.local_all_profile}
              totalCountries={data?.local_all_profile.length}
              role={role}
              tab={tab}
            />
        ))}
      {isMinistriesAndOrganizations &&
        (country === Country.Kuwait ? (
          <OverviewRanking
            tab={tab}
            user={withOrgsIndex.profile + withOrgsIndex.profile_short}
            title={withOrgsIndex.profile}
            country={withOrgsIndex.country_name}
            mode={DisplayMode.MinistriesAndOrganizations}
            platformsRanking={{
              facebook: withOrgsIndex.Facebook_rank,
              instagram: withOrgsIndex.Instagram_rank,
              youtube: withOrgsIndex.Youtube_rank,
              x: withOrgsIndex.Twitter_rank,
            }}
            place={withOrgsIndex.overall_rank}
            totalPlaces={data.ministry_organization_stat}
            evaluation={getEvaluation(withOrgsIndex)}
            topPositions={topWithOrgsPositionsData}
          />
        ) : (
          <RankingMinistries
            tagText={DisplayMode.MinistriesAndOrganizations}
            ministriesData={data?.ministry_organization_all_profile}
            totalCountries={data?.ministry_organization_all_profile.length}
            role={role}
            user={user}
            tab={tab}
          />
        ))}
      {isArabWorld &&
        (role === Role.Ministry ? (
          <OverviewRanking
            tab={tab}
            user={arabWorldIndex.profile + arabWorldIndex.profile_short}
            title={arabWorldIndex.profile}
            country={arabWorldIndex.country_name}
            mode={DisplayMode.ArabWorld}
            platformsRanking={{
              facebook: arabWorldIndex.Facebook_rank,
              instagram: arabWorldIndex.Instagram_rank,
              youtube: arabWorldIndex.Youtube_rank,
              x: arabWorldIndex.Twitter_rank,
            }}
            place={arabWorldIndex.overall_rank}
            totalPlaces={data.arab_world_stat}
            evaluation={getEvaluation(arabWorldIndex)}
            topPositions={topArabWorldPositionsData}
          />
        ) : Role.Organization ? (
          // Placeholder or empty fragment
          <></>
        ):
        (
          <RankingMinistries
            tagText={DisplayMode.ArabWorld}
            user={user}
            ministriesData={data?.arab_world_all_profile}
            totalCountries={data?.arab_world_all_profile.length}
            role={role}
            tab={tab}
          />
        ))}
      <Row className={"one-platform-charts-wrapper"} style={{ direction: isArab ? "rtl" : "ltr" }}>
        <DashboardCardTemplate title={t("pointer.overviewPage.complex-diagram")}>
          <Flex align={"center"} gap={8} style={{ marginBottom: 32 }}>
          <Typography.Text className={"overview-stats-text big"} style={{ fontSize: 18, fontWeight: 500,fontFamily: "'Tajawal', 'Inter', sans-serif"  }}>
          {chartType}
          </Typography.Text>
          </Flex>
          <div className={`complex-chart-container ${isArab && "rtl"}`}>
             <MultiLineGraph data={chartData} />
             <Select
               suffixIcon={<ArrowIcon />}
               selectedValue={chartType}
               setChartType={setChartType}
               options={chartTypes}
               className={"complex-select"}
             />
           </div>
         
        </DashboardCardTemplate>
        {tabContentKey !== "Youtube" &&  (
          <DashboardCardTemplate title={t("pointer.overviewPage.Engagement-Days")}>
            <ProgressBar items={engagementData} />
          </DashboardCardTemplate>
        
        )}
      </Row>
      <StatsCards data={statsCardsData} />
    </Flex>
  )
}

export { OverviewOnePlatformSection }
