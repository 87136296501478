import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import Backend from 'i18next-xhr-backend';
import en from "./en-translations.json";
import ar from "./ar-translations.json";

// order and from where user language should be detected
// const languageDetector = new LanguageDetector();
// languageDetector.init({
//   order: ['localStorage'],
// })

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: false,
    resources: {
      en: {
        translation: en,
      },
      ar: {
        translation: ar,
      },
    },
    detection: {
      order: ["localStorage"],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    wait: true,
  });
export default i18n;
