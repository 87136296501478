import { DatePicker } from "antd"
import { RangePickerProps } from "antd/es/date-picker"
import dayjs from "dayjs"
import { useState } from "react"
import { useMediaQuery } from "react-responsive"

const presets: RangePickerProps["presets"] = [
  { label: "This week", value: [dayjs().startOf("week"), dayjs().endOf("week")] },
  {
    label: "Last week",
    value: [dayjs().subtract(1, "week").startOf("week"), dayjs().subtract(1, "week").endOf("week")],
  },
  { label: "This month", value: [dayjs().startOf("month"), dayjs().endOf("month")] },
  {
    label: "Last month",
    value: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")],
  },
  { label: "This year", value: [dayjs().startOf("year"), dayjs().endOf("year")] },
  {
    label: "Last year",
    value: [dayjs().subtract(1, "year").startOf("year"), dayjs().subtract(1, "year").endOf("year")],
  },
  { label: "All time", value: [dayjs().subtract(50, "years"), dayjs()] },
]

export const RangePicker: React.FC<any> = ({ value, defaultValue, onChange, ...props }) => {
  const [currentValue, setCurrentValue] = useState<RangePickerProps["value"]>(value || defaultValue || presets[0].value)
  const isMobile = useMediaQuery({ maxWidth: 576 })
  const handleChange = (v: RangePickerProps["value"]) => {
    setCurrentValue(v)
    if (onChange) {
      onChange(v)
    }
  }

  return (
    <DatePicker.RangePicker
      value={currentValue}
      onChange={handleChange}
      format={"D MMM, YYYY"}
      presets={isMobile ? null : presets}
      {...props}
    />
  )
}
