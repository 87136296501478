import { Select as SelectInput } from "antd"
import { FC, ReactNode } from "react"

import { useApp } from "../../app"

import { ComplexChartType, DisplayMode } from "../../../types/enums"

import "./Select.less"

const Select: FC<{
  suffixIcon: ReactNode
  selectedValue: DisplayMode | ComplexChartType | string | null
  setDisplayMode?: (displayMode: DisplayMode) => void
  setChartType?: (selectedValue: ComplexChartType) => void
  setDay?: (selectedValue: string) => void
  options: DisplayMode[] | ComplexChartType[] | string[]
  className: "display-mode-select" | "complex-select" | "day-select"
}> = ({ suffixIcon, selectedValue, setDisplayMode, setDay, setChartType, options, className, ...props }) => {
  const { isArab } = useApp()

  const handleChange = (value: DisplayMode | ComplexChartType | string) => {
    setDisplayMode && setDisplayMode(value as DisplayMode)
    setChartType && setChartType(value as ComplexChartType)
    setDay && setDay(value)
  }

  const modifiedOptions = options.map(el => ({
    label: el,
    value: el,
  }))

  return (
    <SelectInput
      suffixIcon={suffixIcon}
      value={selectedValue}
      onChange={value => handleChange(value)}
      // className={className}
      options={modifiedOptions}
      className={isArab ? `arab ${className}` : className}
      style={{ direction: isArab ? "rtl" : "ltr" }}
      {...props}
    />
  )
}

export { Select }
