import { FC, lazy } from "react";
import { Outlet, RouteObject, Navigate } from "react-router-dom";
import DefaultLayout, { Loader } from "../components/layout";

const Home = lazy<FC>(() => import("./home"));
const Login = lazy<FC>(() => import("./login"));
const ForgotPassword = lazy<FC>(() => import("./forgot-password"));
const ResetPassword = lazy<FC>(() => import("./reset-password"));
const GeneralInfo = lazy<FC>(() => import("./general-info"));
const Overview = lazy<FC>(() => import("./overview"));
const DetailedInsights = lazy<FC>(() => import("./detailed-insights"));
const Ranking = lazy<FC>(() => import("./ranking"));
const Logout = lazy<FC>(() => import("./logout"));

const unauthRoutes: RouteObject[] = [
  {
    path: "*",
    element: <Navigate to="/login" replace />,
  },
  {
    path: "",
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
      {
        Component: Login,
        path: "/login",
      },
      {
        Component: ForgotPassword,
        path: "/forgot-password",
      },
      {
        Component: ResetPassword,
        path: "/reset-password",
      },
    ],
  },
];

const authRoutes: RouteObject[] = [
  {
    path: "*",
    element: <Navigate to="/overview" replace />,
  },
  {
    path: "",
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        path: "/",
        element: <Navigate to="/overview" replace />,
      },
      {
        Component: GeneralInfo,
        path: "/general-info",
      },
      {
        Component: Overview,
        path: "/overview",
      },
      {
        Component: DetailedInsights,
        path: "/detailed-insights",
      },
      {
        Component: Ranking,
        path: "/ranking-and-evaluation",
      },
      {
        Component: Logout,
        path: "/logout",
      },
    ],
  },
];

const undefinedAuthorization: RouteObject[] = [
  {
    path: "*",
    element: <Outlet />,
  },
];

export const routes = (authorized: boolean | undefined): RouteObject[] => {
  if (authorized === undefined) {
    return undefinedAuthorization;
  }
  return authorized ? authRoutes : unauthRoutes;
};

export default routes;
