import { Progress, Row, Typography } from "antd"
import { FC } from "react"

import { useApp } from "../../app"

import { ProgressData } from "../../../types/dataTypes"

import "./ProgressBar.less"

const ProgressBar: FC<{ items: ProgressData[] }> = ({ items }) => {
  const { isArab } = useApp()

  const maxValue = [...items].sort((firstItem, secondItem) => secondItem.value - firstItem.value)[0].value

  return (
    <div style={{ direction: isArab ? "rtl" : "ltr" }}>
      {items.map(item => {
        const displayValue = item.value
          .toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            useGrouping: true,
          })
          .split(",")[0]
        const percent = Math.round((item.value / maxValue) * 100)

        return (
          <div key={item.label} className={"progress-info-wrapper"}>
            <Typography.Paragraph className={"progress-label"}>
              {isArab ? item.label_ar : item.label} {/* Display Arabic label if isArab is true, otherwise display English label */}
            </Typography.Paragraph>
            <Row align={"bottom"} className={"progress-wrapper"}>
              <Progress percent={percent} showInfo={false} />
              <Typography.Text className={"progress-value"}>{displayValue}</Typography.Text>
            </Row>
          </div>

        )
      })}
    </div>
  )
}

export { ProgressBar }
