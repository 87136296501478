import React from "react"

import { useApp } from "../../app"

import type { StatCardProps } from "./StatCard"
import { StatCard } from "./StatCard"

import { Col, Flex, Row, Typography } from "antd"
import { CardContainer } from "src/components/ui/CardContainer"
import { MinistriesStatsData } from "../MinistriesDetailedInsights/MinistriesDetailedInsights"
import "./StatsCards.less"

type StatsCardsProps = {
  data?: StatCardProps[] | undefined
  ministriesData?: MinistriesStatsData[] | undefined
}

export const StatsCards: React.FC<StatsCardsProps> = ({ data, ministriesData }) => {
  const { isArab } = useApp()

  return (
    <CardContainer className={`${ministriesData && "vertical"}`}>
      <Row style={{ direction: isArab ? "rtl" : "ltr", width: "100%" }} className={"cards-wrapper"}>
        {data &&
          data.map(stats => (
            <Col key={stats.title}>
              <StatCard {...stats} />
            </Col>
          ))}
      </Row>

      {ministriesData &&
        ministriesData.map(data => (
          <Flex vertical gap={48} style={{ direction: isArab ? "rtl" : "ltr" }}>
            <Typography.Text className={"comparing-label"}>{data.title}</Typography.Text>
            <Row justify={"center"} style={{ marginBottom: 48 }}>
              {data.ministries?.map(ministry => (
                <Col
                  xs={24}
                  sm={24}
                  md={24 / 2}
                  lg={24 / (data?.ministries?.length as number)}
                  className={"card-wrapper"}
                >
                  <StatCard
                    key={ministry.ministry}
                    title={ministry.ministry}
                    title_ar={ministry.ministry}
                    number={ministry.number}
                    gainedNumber={ministry.number - ministry.lastMonthNumber}
                  />
                </Col>
              ))}
            </Row>
          </Flex>
        ))}
    </CardContainer>
  )
}
