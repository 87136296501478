import { Flex, Typography } from "antd"
import { FC } from "react"

import { useMediaQuery } from "react-responsive"
import { useApp } from "../../app"

import "./ExpandedRankingCardContent.less"

const ExpandedRankingCardContent: FC<{ label: string; value: string | number | undefined; outOf: string }> = ({
  label,
  value,
  outOf,
}) => {
  const { isArab } = useApp()
  const isMobileXs = useMediaQuery({ maxWidth: 424 })

  return (
    <Flex
      align={isMobileXs ? "start" : "center"}
      justify={"space-between"}
      vertical={isMobileXs}
      style={{ direction: isArab ? "rtl" : "ltr" }}
    >
      <Typography.Text className={"ranking-label"}>{label}</Typography.Text>
      <Typography.Text>
        <Typography.Text className={"ranking-value"}>{value}</Typography.Text>
        <Typography.Text className={"ranking-total-value"}>/{outOf}</Typography.Text>
      </Typography.Text>
    </Flex>
  )
}

export { ExpandedRankingCardContent }
