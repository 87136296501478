import { Col, ColProps } from "antd"
import { CSSProperties, FC, ReactNode } from "react"
import "./index.less"

interface ContentBoxProps extends ColProps {
  style?: CSSProperties
  className?: string
  children?: ReactNode
}

export const ContentBox: FC<ContentBoxProps> = ({ style, className, children, ...props }) => {
  return (
    <Col className={className ? `content-box ${className}` : "content-box"} style={style} {...props}>
      {children}
    </Col>
  )
}
