import { Layout as BaseLayout, Button, Flex, Tour, TourProps, Typography } from "antd"
import { FC, Suspense, useEffect, useRef, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { useTranslation, Trans } from 'react-i18next';

import { useMediaQuery } from "react-responsive"
import { useApp } from "../app"

import detailedInsights from "../../assets/images/tabs/detailed-insights.png"
import generalInfo from "../../assets/images/tabs/general-info.png"
import overview from "../../assets/images/tabs/overview-tab.png"
import ranking from "../../assets/images/tabs/ranking.png"

import { ReactComponent as AiTourIcon } from "../../assets/icons/ai-tour.svg"
import { ReactComponent as AiIcon } from "../../assets/icons/ai.svg"


import { Footer, Header } from "."
import { AIModal, AIChat, WelcomeModal } from "../ui"
import chatbotIcon from "/public/chatbot.png";

const authPages = ["/login", "/forgot-password", "/reset-password"]
const platformPages = ["/overview", "/detailed-insights", "/ranking-and-evaluation", "/general-info"]

const Layout: FC = () => {
  const path = useLocation().pathname
  const isAuthPage = authPages.includes(path)
  const isPlatform = platformPages.includes(path)

  const isLargeDesktop = useMediaQuery({ minWidth: 1250 })
  const { isArab } = useApp()
  const { t, i18n } = useTranslation();

  useEffect(()=>{
    i18n.changeLanguage(isArab? 'ar': 'en')
  },[])

  const [isWelcomeOpen, setIsWelcomeOpen] = useState(() => {
    const isOnboarded = localStorage.getItem("onboarded")
    return !isPlatform ? false : isOnboarded ? !JSON.parse(isOnboarded) : true
  })
  const [isAiOpen, setIsAiOpen] = useState(false)
  const [isOnboarding, setIsOnboarding] = useState(false)

  const overviewRef = useRef(null)
  const detailsRef = useRef(null)
  const rankingRef = useRef(null)
  const generalRef = useRef(null)
  const aiRef = useRef(null)

  const handleGoClick = () => {
    setIsWelcomeOpen(false)
    setIsOnboarding(true)
  }

  const handleCloseClick = () => {
    localStorage.setItem("onboarded", JSON.stringify(true))
    setIsOnboarding(false)
  }

  const handleAiBtnClick = () => setIsAiOpen(prevState => !prevState)

  const steps: TourProps["steps"] = [
    {
      cover: !isLargeDesktop ? <img src={overview} className={"small"} alt={"Overview"} /> : null,
      title: "Overview",
      description: "This tab is where you'll find the important data that should show on first glance.",
      target: isLargeDesktop ? overviewRef.current : null,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
    {
      cover: !isLargeDesktop ? <img src={detailedInsights} className={"big"} alt={"Detailed Insights"} /> : null,
      title: "Detailed Insights",
      description:
        "Insights contains detailed data obtained from a social media account on the following platforms: Facebook, X, Instagram, Youtube. The extracted data refer to a period of 3 months.",
      target: isLargeDesktop ? detailsRef.current : null,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
    {
      cover: !isLargeDesktop ? <img src={ranking} className={"big"} alt={"Ranking & Evaluation"} /> : null,
      title: "Ranking & Evaluation",
      description:
        "Businesses in the industry are evaluated and ranked based on three key criteria, each with a maximum score of 33.33: Engagement, Number of Posts, Number of Followers. The overall ranking is derived from the sum of these three scores, providing a comprehensive evaluation of the business's performance across all platforms.",
      target: isLargeDesktop ? rankingRef.current : null,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
    {
      cover: !isLargeDesktop ? <img src={generalInfo} className={"small"} alt={"General Info"} /> : null,
      title: "General Info",
      description:
        "Get insights into the organization, its definition, evolution, team members, features, and phases. Also, discover statistics and evaluations for Kuwait and the Arab world.",
      target: isLargeDesktop ? generalRef.current : null,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
    {
      title: (
        <Flex align={"center"} gap={8}>
          <Typography.Text>AI Recommendation</Typography.Text> <AiTourIcon />
        </Flex>
      ),
      description: "This a separate page to give a list of advice generated by AI.",
      target: aiRef.current,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
  ]

  const steps_ar: TourProps["steps"] = [
    {
      cover: !isLargeDesktop ? <img src={overview} className={"small"} alt={"Overview"} /> : null,
      title: "الملخص",
      description: "هنا المكان الذي ستجد فيه البيانات المهمة التي يجب أن تظهر للوهلة الأولى.",
      target: isLargeDesktop ? overviewRef.current : null,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
    {
      cover: !isLargeDesktop ? <img src={detailedInsights} className={"big"} alt={"Detailed Insights"} /> : null,
      title: "رؤى تفصيلية",
      description:
        "تحتوي الرؤى على بيانات تفصيلية تم الحصول عليها من أحد حسابات وسائل التواصل الاجتماعي على الأنظمة الأساسية التالية: Facebook وX وInstagram وYoutube. تشير البيانات المستخرجة إلى فترة 3 أشهر.",
      target: isLargeDesktop ? detailsRef.current : null,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
    {
      cover: !isLargeDesktop ? <img src={ranking} className={"big"} alt={"Ranking & Evaluation"} /> : null,
      title: "الترتيب والتقييم",
      description:
        "يتم تقييم وتصنيف الوزارات / المؤسسات في المجالات المختلفة بناءً على ثلاثة معايير رئيسية، كل منها بحد أقصى 33.33: المشاركة، عدد المشاركات، عدد المتابعين. يتم اشتقاق التصنيف العام من مجموع هذه الدرجات الثلاث، مما يوفر تقييمًا شاملاً لأداء الشركة عبر جميع المنصات.",
      target: isLargeDesktop ? rankingRef.current : null,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
    {
      cover: !isLargeDesktop ? <img src={generalInfo} className={"small"} alt={"General Info"} /> : null,
      title: "معلومات عامة",
      description:
        "احصل على نظرة ثاقبة للمنظمة وتعريفها وتطورها وأعضاء الفريق وميزاتها ومراحلها. اكتشف أيضًا الإحصائيات والتقييمات الخاصة بالكويت والعالم العربي.",
      target: isLargeDesktop ? generalRef.current : null,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
    {
      title: (
        <Flex align={"center"} gap={8}>
          <Typography.Text>توصية الذكاء الاصطناعي</Typography.Text> <AiTourIcon />
        </Flex>
      ),
      description: "هذه صفحة منفصلة لتقديم قائمة بالنصائح الصادرة عن الذكاء الاصطناعي.",
      target: aiRef.current,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
  ]

  return (
    <BaseLayout style={{ display: "flex", flexDirection: "column", alignItems: "stretch", minHeight: "100vh" }}>
      {path !== "/" && !isAuthPage && (
        <BaseLayout.Header ref={overviewRef} className={`header-wrapper ${isArab && "rtl"}`}>
          <Header
            rankingRef={rankingRef}
            overviewRef={overviewRef}
            generalRef={generalRef}
            detailsRef={detailsRef}
            setIsOnboarding={setIsWelcomeOpen}
          />
          <Tour
            open={isOnboarding}
            closeIcon={<Typography.Text>{t('pointer.general.btn.dismiss')}</Typography.Text>}
            disabledInteraction={true}
            steps={isArab? steps_ar : steps}
            onClose={handleCloseClick}
            indicatorsRender={(current, total) => (
              <Typography.Text>
                {current + 1} of {total}
              </Typography.Text>
            )}
          />
        </BaseLayout.Header>
      )}
      <BaseLayout.Content>
        <Suspense>
          <Outlet />
        </Suspense>
        <WelcomeModal isOpen={isWelcomeOpen} handleClick={handleGoClick} />
        {/* {path !== "/" && !isAuthPage && (
          <Button ref={aiRef} type={"link"} onClick={handleAiBtnClick} className={`ai-btn ${isArab && "rtl"}`}>
          <img src={chatbotIcon} alt={"Chatbot"} style={{ width: '62px', height: '62px' }} />
        </Button>
        )} */}
        <AIChat open={isAiOpen} handleClose={handleAiBtnClick} />
      </BaseLayout.Content>
      {path === "/" && (
        <BaseLayout.Footer style={{ padding: 0, backgroundColor: "#080E26" }}>
          <Footer />
        </BaseLayout.Footer>
      )}
    </BaseLayout>
  )
}

export { Layout }
