import { BarElement, CategoryScale, Chart as ChartJS, ChartType, LinearScale, Plugin, Tooltip } from "chart.js"
import { FC, useEffect, useMemo, useRef, useState } from "react"
import { Bar } from "react-chartjs-2"

import { formatter } from "src/helpers/numberFormatter"
import { useApp } from "../app"

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)

interface GetImagePluginPluginOptions {
  onChartImageUrlChange?: (newUrl: string) => void
}

declare module "chart.js" {
  interface PluginOptionsByType<TType extends ChartType> {
    getImagePlugin?: GetImagePluginPluginOptions
  }
}

const getImagePlugin = {
  id: "getImagePlugin",
  afterRender: (chart: any, args: any, options: GetImagePluginPluginOptions) => {
    const onChartImageUrlChange = options.onChartImageUrlChange
    if (onChartImageUrlChange) {
      if (chart.updateCount > chart.lastUpdateCount) {
        onChartImageUrlChange(chart.canvas.toDataURL("image/png"))
        chart.lastUpdateCount = chart.updateCount
      }
    }
  },
}

ChartJS.register(getImagePlugin)

export type BarsGraphProps = {
  data: {
    labels: string[]
    datasets: {
      label?: string
      data: number[]
      color: string
    }[]
  }
  chartType?: string
  onChartImageUrlChange?: (newUrl: string | null, chartType: string) => void
}

export const BarsGraph: FC<BarsGraphProps> = ({ data, chartType, onChartImageUrlChange }) => {
  const { isArab } = useApp()
  const chartRef = useRef<any>(null)
  const [prevData, setPrevData] = useState(data)
  const [imageSaved, setImageSaved] = useState(false)

  useEffect(() => {
    const chart = chartRef.current
    if (chart) {
      chart.updateCount = (chart.updateCount || 0) + 1
      chart.lastUpdateCount = chart.updateCount - 1
      chart.update()
      if (!imageSaved && onChartImageUrlChange) {
        onChartImageUrlChange(null, chartType || "")
        setImageSaved(true)
      }
    }
    setPrevData(data)
  }, [data])

  const rightYTicks: Plugin = {
    id: "rightYTicks",
    afterDatasetsDraw: chart => {
      const {
        ctx,
        scales,
        chartArea: { right },
      } = chart
      scales.y.ticks.map((tick, i) => {
        const label = (tick.label as string) || tick.value
        ctx.font = "14px Inter"
        ctx.fillStyle = "#757A89"
        ctx.fillText(label.toString(), right + 35, scales.y.getPixelForTick(i))
      })
    },
  }

  const plugins = useMemo(() => {
    const pluginsList = []
    if (isArab) pluginsList.push(rightYTicks)
    pluginsList.push(getImagePlugin)
    return pluginsList
  }, [isArab, rightYTicks])

  const memoizedOptions = useMemo(
    () => ({
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: { display: false },
        tooltip: {
          enabled: data.datasets.length > 1,
          intersect: false,
          backgroundColor: "#fff",
          bodyColor: "#757A89",
          titleColor: "#757A89",
          titleFont: {
            family: "Inter",
            size: 12,
            lineHeight: 1.66,
            weight: 500,
          },
          bodyFont: {
            family: "Inter",
            size: 12,
            lineHeight: 1.66,
            weight: 400,
          },
          cornerRadius: 8,
          callbacks: {
            label: (context: any) => {
              if (context.dataset.label === "Outcoming") {
                return ` ${context.dataset.label}: -${context.raw}`
              }
              return ` ${context.dataset.label}: ${context.raw}`
            },
          },
        },
        getImagePlugin: {
          onChartImageUrlChange: (url: any) => {
            if (!imageSaved) {
              onChartImageUrlChange && onChartImageUrlChange(url, chartType || "")
              setImageSaved(true)
            }
          },
        },
      },
      layout: {
        padding: isArab ? 70 : 0,
      },
      scales: {
        x: {
          grid: {
            color: "#F3F3F5",
            offset: false,
            drawTicks: false,
          },
          border: { color: "#757A89" },
          ticks: {
            padding: 10,
          },
          reverse: isArab,
        },
        y: {
          grid: { color: "#F3F3F5" },
          border: {
            display: !isArab,
            dash: [12],
          },
          ticks: {
            display: !isArab,
            padding: 15,
            font: {
              size: 14,
              lineHeight: 1.21,
            },
            color: "#757A89",
            callback: (value: any) => {
              if (value === 0) {
                return 0
              }

              if (typeof value === "number") {
                return formatter.format(value)
              }

              return value
            },
          },
        },
      },
    }),
    [isArab, data.datasets.length, chartType, onChartImageUrlChange, imageSaved]
  )

  const memoizedData = useMemo(
    () => ({
      labels: data.labels,
      datasets: data.datasets.map(dataset => ({
        data: dataset.data,
        backgroundColor: dataset.color,
        label: dataset.label,
        borderRadius: 2,
        barPercentage: data.datasets.length > 1 ? 1 : 0.5,
      })),
    }),
    [data]
  )

  return <Bar ref={chartRef} plugins={plugins} options={memoizedOptions} data={memoizedData} />
}
