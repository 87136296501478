import { Card, Typography } from "antd"
import { CSSProperties, FC, PropsWithChildren, ReactNode } from "react"

import { useApp } from "../../app"

import clsx from "clsx"
import "./DashboardCardTemplate.less"

const DashboardCardTemplate: FC<
  PropsWithChildren<{ title?: string | ReactNode; className?: string; style?: CSSProperties }>
> = ({ title, children, className, style }) => {
  const { isArab } = useApp()

  return (
    <Card className={clsx("dashboard-card", className)} style={style}>
      {title && typeof title === "string" ? (
        <Typography.Title level={1} className={"dashboard-card-title"} style={{ direction: isArab ? "rtl" : "ltr" }}>
          {title}
        </Typography.Title>
      ) : (
        title
      )}
      {children}
    </Card>
  )
}

export { DashboardCardTemplate }
