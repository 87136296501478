import { ColProps, Flex } from "antd"
import { CSSProperties, FC, ReactNode } from "react"
import "./index.less"

interface ContainerProps extends ColProps {
  style?: CSSProperties
  className?: string
  children?: ReactNode
}

export const Container: FC<ContainerProps> = ({ style, className, children, ...props }) => {
  return (
    <Flex
      vertical
      className={className ? `custom-container ${className}` : "custom-container"}
      style={style}
      {...props}
    >
      {children}
    </Flex>
  )
}
