import { ArcElement, Chart as ChartJS, PieController } from "chart.js"

import { Pie } from "react-chartjs-2"

ChartJS.register(PieController, ArcElement)

export type DoughnutGraphProps = {
  data: {
    label: string
    value: number
    color: string
  }[]
}

export const DoughnutGraph: React.FC<DoughnutGraphProps> = ({ data }) => {
  return (
    <Pie
      options={{
        cutout: 60,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      }}
      data={{
        labels: data.map(it => it.label),
        datasets: [
          {
            data: data.map(it => it.value),
            backgroundColor: data.map(it => it.color),
          },
        ],
      }}
    />
  )
}
