import { Button, ButtonProps } from "antd"
import { CSSProperties, FC, ReactNode } from "react"
import "./index.less"

interface CustomBtnProps extends ButtonProps {
  style?: CSSProperties
  className?: string
  children?: ReactNode
}

export const CustomBtn: FC<CustomBtnProps> = ({ style, className, children, ...props }) => {
  return (
    <Button className={className ? `custom-btn ${className}` : "custom-btn"} style={style} {...props}>
      {children}
    </Button>
  )
}
