import { Col, Flex, Row, Typography } from "antd";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApp } from "../../app";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg";
import {
  platformsLabels,
  statsAccountsLabel,
  statsQualifiedLabels,
  platformsLabels_ar,
  statsAccountsLabel_ar,
  statsQualifiedLabels_ar,
} from "../../../shared/fake-data/overviewChartsData";
import { BarChart } from "../../charts/BarChart/BarChart";
import { MultiLineGraph } from "../../charts/MultiLineGraph";
import { DashboardCardTemplate, OverviewRanking, Select } from "../../ui";
import { ComplexChartType, ComplexChartType_ar, DisplayMode, DisplayMode_ar, Mode, Role, Country } from "../../../types/enums";
import dayjs from "dayjs";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import "./OverviewAllPlatformsSection.less";
import { RankingMinistries } from "../RankingMinistries"







// Define the available chart types for both Arabic and non-Arabic contexts
const chartTypes: ComplexChartType[] = [
  ComplexChartType.Followers,
  ComplexChartType.Likes,
  ComplexChartType.Comments,
  ComplexChartType.Posts,
];

const chartTypes_ar: ComplexChartType_ar[] = [
  ComplexChartType_ar.Followers,
  ComplexChartType_ar.Likes,
  ComplexChartType_ar.Comments,
  ComplexChartType_ar.Posts,
];



// Functional component for OverviewAllPlatformsSection
const OverviewAllPlatformsSection: FC<{
  tab: string;
  mode: Mode;
  data: any;
  displayMode: DisplayMode | DisplayMode_ar | null; // Updated type to include null
}> = ({ mode, displayMode, data, tab }) => {
  const { isArab, role, country } = useApp();
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useBreakpoints();
  const [chartType, setChartType] = useState(chartTypes[0]); // State for selected chart type

  const getCurrentDisplayMode = (
    isArab: boolean,
    specificMode: DisplayMode | DisplayMode_ar | null | undefined
  ): DisplayMode | DisplayMode_ar => {
    if (!specificMode) {
      return isArab ? DisplayMode_ar.Ministries : DisplayMode.Ministries; // Default value
    }
  
    if (isArab) {
      // Return the Arabic version of the mode
      switch (specificMode) {
        case DisplayMode.Ministries:
        case DisplayMode_ar.Ministries:
          return DisplayMode_ar.Ministries;
        case DisplayMode.MinistriesAndOrganizations:
        case DisplayMode_ar.MinistriesAndOrganizations:
          return DisplayMode_ar.MinistriesAndOrganizations;
        case DisplayMode.ArabWorld:
        case DisplayMode_ar.ArabWorld:
          return DisplayMode_ar.ArabWorld;
        case DisplayMode.ArabWorldCategory:
        case DisplayMode_ar.ArabWorldCategory:
          return DisplayMode_ar.ArabWorldCategory;
        default:
          return specificMode as DisplayMode_ar; // Assume it's already Arabic
      }
    } else {
      // Return the English version of the mode
      switch (specificMode) {
        case DisplayMode_ar.Ministries:
        case DisplayMode.Ministries:
          return DisplayMode.Ministries;
        case DisplayMode_ar.MinistriesAndOrganizations:
        case DisplayMode.MinistriesAndOrganizations:
          return DisplayMode.MinistriesAndOrganizations;
        case DisplayMode_ar.ArabWorld:
        case DisplayMode.ArabWorld:
          return DisplayMode.ArabWorld;
        case DisplayMode_ar.ArabWorldCategory:
        case DisplayMode.ArabWorldCategory:
          return DisplayMode.ArabWorldCategory;
        default:
          return specificMode as DisplayMode; // Assume it's already English
      }
    }
  };
  
  // Check if the role is "Ministry" before preparing the data
  let topLocalPositionsData: any[] = [];
  let localIndex = null;
  let country_name = null;

  if (role === "Ministry") {
    // Prepare data for rankings only if the role is "Ministry"
    localIndex = data.local_match_profile;
    country_name = localIndex.country_name;
    topLocalPositionsData = data.local_all_profile.map((item: any, index: number) => ({
      src: item.p_avatar, // Profile avatar
      title: item.overall_rank, // Overall rank
      description: item.profile, // Profile description
      country: "", // Country (empty string for now)
      uniqueId: item.profile + item.profile_short, // Unique identifier combining profile and profile_short
      ranking: index + 1, // Ranking based on the array index (1-based)
    }));
  }

  else {
    const profileInfo = JSON.parse(localStorage?.getItem("profileInfo") || "{}")
    console.log(profileInfo);
    country_name = isArab ? profileInfo?.data?.title_ar : profileInfo?.data?.title_en;
  }
  
  
  const withOrgsIndex = data.ministry_organization_match_profile;
  const topWithOrgsPositionsData = data.ministry_organization_all_profile.map((item: any, index: number) => ({
    src: item.p_avatar,
    title: item.overall_rank,
    description: item.profile,
    country: "",
    uniqueId: item.profile + item.profile_short,
    ranking: index + 1,
  }));

  const arabWorldIndex = data.arab_world_match_profile;
  const topArabWorldPositionsData = data.arab_world_all_profile.map((item: any, index: number) => ({
    src: item.p_avatar,
    title: item.overall_rank,
    description: item.profile,
    country: item.country_name,
    uniqueId: item.profile + item.profile_short,
    ranking: index + 1,
  }));

  const arabWorldCategoryIndex = data.arab_world_category_match_profile;
  const topArabWorldCategoryPositionsData = data.arab_world_category_all_profile.map((item: any, index: number) => ({
    src: item.p_avatar,
    title: item.overall_rank,
    description: item.profile,
    country: item.country_name,
    uniqueId: item.profile + item.profile_short,
    ranking: index + 1,
  }));

  // Extract profile information from local storage
  const profileInfo = JSON.parse(localStorage.getItem("profileInfo") || "{}");
  const startDate = profileInfo.data?.start_date ? dayjs(profileInfo.data.start_date) : null;
  const endDate = profileInfo.data?.end_date ? dayjs(profileInfo.data.end_date) : null;
  const daysDifference = startDate && endDate ? endDate.diff(startDate, 'day') : null;

  // Generate date labels for charts
  const days = startDate && daysDifference !== null ? Array.from({ length: daysDifference + 1 }, (_, i) =>
    startDate.add(i, 'day').format('DD MMM')
  ) : [];

  // Get chart data based on the selected chart type
  const getChartData = (profileData: any, chartType: ComplexChartType) => {
    switch (chartType) {
      case ComplexChartType.Followers:
        return profileData.followers_series;
      case ComplexChartType.Likes:
        return profileData.likes_series;
      case ComplexChartType.Comments:
        return profileData.comments_series;
      case ComplexChartType.Posts:
        return profileData.posts_series;
      default:
        return [];
    }
  };

  // Handle the chart type change
  const handleChartTypeChange = (value: ComplexChartType | ComplexChartType_ar) => {
    const selectedChartType = isArab
      ? (Object.keys(ComplexChartType) as Array<keyof typeof ComplexChartType>).find(
          key => ComplexChartType_ar[key] === value
        )
      : value;

    setChartType(selectedChartType as ComplexChartType);
  };

  // Determine which options to use based on the language
  const chartOptions = isArab ? chartTypes_ar : chartTypes;

  // Data for the complex followers line chart
  const complexFollowersData = {
    labels: days,
    datasets: [
      {
        label: isArab ? "انستقرام" : "Instagram",
        borderColor: "#6048F3",
        data: getChartData(data.instagram_profile, chartType),
      },
      {
        label: isArab ? "فيسبوك" : "Facebook",
        borderColor: "#219AFF",
        data: getChartData(data.facebook_profile, chartType),
      },
      {
        label: isArab ? "يوتيوب" : "Youtube",
        borderColor: "#DA1818",
        data: getChartData(data.youtube_profile, chartType),
      },
      {
        label: "X",
        borderColor: "#757A89",
        data: getChartData(data.twitter_profile, chartType),
      },
    ],
  };

  // Get evaluation score based on the selected tab
  const getEvaluation = (data: any) => {
    switch (tab) {
      case "All platforms":
        return data.overall_rank_score;
      case "Facebook":
        return data.Facebook_total_score;
      case "Instagram":
        return data.Instagram_total_score;
      case "Youtube":
        return data.Youtube_total_score;
      case "X":
        return data.Twitter_total_score;
      default:
        return "Unknown";
    }
  };

  return (
    <Flex vertical gap={24}>
      {/* Overview Ranking for Ministry */}
  {role === Role.Ministry && localIndex != null && (
    <>
      {/* Show for Mode.All */}
      {mode === Mode.All && (
        <OverviewRanking
          tab={tab}
          user={localIndex.profile + localIndex.profile_short}
          title={localIndex.profile}
          country={localIndex.country_name}
          mode={getCurrentDisplayMode(isArab, displayMode)}
          platformsRanking={{
            facebook: localIndex.Facebook_rank,
            instagram: localIndex.Instagram_rank,
            youtube: localIndex.Youtube_rank,
            x: localIndex.Twitter_rank,
          }}
          place={localIndex.overall_rank}
          totalPlaces={data.local_profile_stat}
          evaluation={getEvaluation(localIndex)}
          topPositions={topLocalPositionsData}
          allPlatformRender
        />
      )}

      {/* Show for Mode.Select and DisplayMode.Ministries */}
      {mode === Mode.Select && displayMode === getCurrentDisplayMode(isArab, DisplayMode.Ministries) && (
        <OverviewRanking
          tab={tab}
          user={localIndex.profile + localIndex.profile_short}
          title={localIndex.profile}
          country={localIndex.country_name}
          mode={getCurrentDisplayMode(isArab, DisplayMode.Ministries)}
          platformsRanking={{
            facebook: localIndex.Facebook_rank,
            instagram: localIndex.Instagram_rank,
            youtube: localIndex.Youtube_rank,
            x: localIndex.Twitter_rank,
          }}
          place={localIndex.overall_rank}
          totalPlaces={data.local_profile_stat}
          evaluation={getEvaluation(localIndex)}
          topPositions={topLocalPositionsData}
          allPlatformRender
        />
      )}
    </>
  )}

  {/* Overview Ranking for Ministries and Organizations */}
  {role === Role.Ministry && country === Country.Kuwait && localIndex != null && (
    <>
      {/* Show for Mode.All */}
      {mode === Mode.All && (
        <OverviewRanking
          tab={tab}
          user={withOrgsIndex.profile + withOrgsIndex.profile_short}
          title={withOrgsIndex.profile}
          country={withOrgsIndex.country_name}
          mode={getCurrentDisplayMode(isArab, DisplayMode.MinistriesAndOrganizations)}
          platformsRanking={{
            facebook: withOrgsIndex.Facebook_rank,
            instagram: withOrgsIndex.Instagram_rank,
            youtube: withOrgsIndex.Youtube_rank,
            x: withOrgsIndex.Twitter_rank,
          }}
          place={withOrgsIndex.overall_rank}
          totalPlaces={data.ministry_organization_stat}
          evaluation={getEvaluation(withOrgsIndex)}
          topPositions={topWithOrgsPositionsData}
          allPlatformRender
        />
      )}

      {/* Show for Mode.Select and DisplayMode.MinistriesAndOrganizations */}
      {mode === Mode.Select && displayMode === getCurrentDisplayMode(isArab, DisplayMode.MinistriesAndOrganizations) && (
        <OverviewRanking
          tab={tab}
          user={withOrgsIndex.profile + withOrgsIndex.profile_short}
          title={withOrgsIndex.profile}
          country={withOrgsIndex.country_name}
          mode={getCurrentDisplayMode(isArab, DisplayMode.MinistriesAndOrganizations)}
          platformsRanking={{
            facebook: withOrgsIndex.Facebook_rank,
            instagram: withOrgsIndex.Instagram_rank,
            youtube: withOrgsIndex.Youtube_rank,
            x: withOrgsIndex.Twitter_rank,
          }}
          place={withOrgsIndex.overall_rank}
          totalPlaces={data.ministry_organization_stat}
          evaluation={getEvaluation(withOrgsIndex)}
          topPositions={topWithOrgsPositionsData}
          allPlatformRender
        />
      )}
    </>
  )}

  {/* Overview Ranking for Arab World */}
  {role === Role.Ministry && localIndex != null && (
    <>
      {/* Show for Mode.All */}
      {mode === Mode.All && (
        <OverviewRanking
          tab={tab}
          user={arabWorldIndex.profile + arabWorldIndex.profile_short}
          title={arabWorldIndex.profile}
          country={arabWorldIndex.country_name}
          mode={getCurrentDisplayMode(isArab, DisplayMode.ArabWorld)}
          platformsRanking={{
            facebook: arabWorldIndex.Facebook_rank,
            instagram: arabWorldIndex.Instagram_rank,
            youtube: arabWorldIndex.Youtube_rank,
            x: arabWorldIndex.Twitter_rank,
          }}
          place={arabWorldIndex.overall_rank}
          totalPlaces={data.arab_world_stat}
          evaluation={getEvaluation(arabWorldIndex)}
          topPositions={topArabWorldPositionsData}
          allPlatformRender
        />
      )}

      {/* Show for Mode.Select and DisplayMode.ArabWorld */}
      {mode === Mode.Select && displayMode === getCurrentDisplayMode(isArab, DisplayMode.ArabWorld) && (
        <OverviewRanking
          tab={tab}
          user={arabWorldIndex.profile + arabWorldIndex.profile_short}
          title={arabWorldIndex.profile}
          country={arabWorldIndex.country_name}
          mode={getCurrentDisplayMode(isArab, DisplayMode.ArabWorld)}
          platformsRanking={{
            facebook: arabWorldIndex.Facebook_rank,
            instagram: arabWorldIndex.Instagram_rank,
            youtube: arabWorldIndex.Youtube_rank,
            x: arabWorldIndex.Twitter_rank,
          }}
          place={arabWorldIndex.overall_rank}
          totalPlaces={data.arab_world_stat}
          evaluation={getEvaluation(arabWorldIndex)}
          topPositions={topArabWorldPositionsData}
          allPlatformRender
        />
      )}
    </>
  )}

  {/* Prime Minister view for Ministries */}
  {role === Role.PrimeMinister && (
    <>
      <RankingMinistries
        tagText={DisplayMode.Ministries}
        user={'user'}
        ministriesData={data?.local_all_profile}
        totalCountries={data?.local_all_profile.length || 0}
        role={role}
        tab={tab}
      />

      {/* Prime Minister view for Ministries and Organizations */}
      <RankingMinistries
        tagText={DisplayMode.MinistriesAndOrganizations}
        user={'user'}
        ministriesData={data?.ministry_organization_all_profile}
        totalCountries={data?.ministry_organization_all_profile.length || 0}
        role={role}
        tab={tab}
      />

      {/* Prime Minister view for Arab World */}
      <RankingMinistries
        tagText={DisplayMode.ArabWorld}
        user={'user'}
        ministriesData={data?.arab_world_all_profile}
        totalCountries={data?.arab_world_all_profile.length || 0}
        role={role}
        tab={tab}
      />
    </>
  )}


      <Row justify={"space-between"} gutter={[24, 24]} style={{ direction: isArab ? "rtl" : "ltr" }}>
        {/* Statistics Bar Charts Section */}
        <Col span={!isDesktop ? 24 : 12}>
          <DashboardCardTemplate
            title={
              <Flex gap={16} style={{ marginBottom: 22 }} vertical={isMobile}>
                <Typography.Title level={3} className={"dashboard-card-title"} style={{ marginBottom: 0 }}>
                  {t("pointer.overviewPage.statistics")}
                </Typography.Title>
                <Typography.Text className={"mode-tag blue"} style={{ position: "static" }}>
                  {country_name}
                </Typography.Text>
              </Flex>
            }
            style={{ height: "100%" }}
          >
            <Typography.Paragraph className={"overview-stats-text-blue"}>
              <Typography.Text className={"overview-stats-text-blue big"} style={{ fontFamily: "'Tajawal', 'Inter', sans-serif" }}>
                {data.total_profile} {t("pointer.overviewPage.ministries")}
              </Typography.Text>
            </Typography.Paragraph>
            <Row className={"bar-charts-row-wrapper"} gutter={[36, 24]}>
              <Col xs={24} sm={24} md={12}>
                <BarChart
                  labels={isArab ? statsAccountsLabel_ar : statsAccountsLabel}
                  data={[data.chart_profile.overall_exist, data.chart_profile.overall_not_exist]}
                  tickStep={20}
                  minTick={0}
                  height={300}
                  displayPercentsAndValues={true}
                />
                <Typography.Text className={"overview-stats-text"} style={{ marginTop: 28, marginLeft: 20, fontSize: 14 }}>
                  <Typography.Text className={"overview-stats-text big"} style={{ fontSize: 18, fontWeight: 500, fontFamily: "'Tajawal', 'Inter', sans-serif" }}>
                    {data.chart_profile.overall_total} {t("pointer.overviewPage.assumed-socials")}
                  </Typography.Text>
                </Typography.Text>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <BarChart
                  labels={isArab ? statsQualifiedLabels_ar : statsQualifiedLabels}
                  data={[data.chart_profile.overall_verified, data.chart_profile.overall_pending]}
                  tickStep={20}
                  minTick={0}
                  height={300}
                  displayPercentsAndValues={true}
                />
                <Typography.Text className={"overview-stats-text"} style={{ marginTop: 28, marginLeft: 20, fontSize: 14 }}>
                  <Typography.Text className={"overview-stats-text big"} style={{ fontSize: 18, fontWeight: 500, fontFamily: "'Tajawal', 'Inter', sans-serif" }}>
                    {data.chart_profile.overall_exist} {t("pointer.overviewPage.existing-socials")}
                  </Typography.Text>
                </Typography.Text>
              </Col>
            </Row>
          </DashboardCardTemplate>
        </Col>
        
        {/* Most Used Platforms Bar Chart */}
        <Col span={!isDesktop ? 24 : 12}>
          <DashboardCardTemplate
            title={
              <Flex gap={16} style={{ marginBottom: 24 }} vertical={isMobile}>
                <Typography.Title level={3} className={"dashboard-card-title"} style={{ marginBottom: 0 }}>
                  {t("pointer.overviewPage.most-usesd")}
                </Typography.Title>
                <Typography.Text className={"mode-tag blue"} style={{ position: "static" }}>
                  {country_name}
                </Typography.Text>
              </Flex>
            }
            style={{ height: "100%" }}
          >
            <BarChart
              labels={isArab ? platformsLabels_ar : platformsLabels}
              data={[
                data.chart_profile.instagram_percentage,
                data.chart_profile.twitter_percentage,
                data.chart_profile.youtube_percentage,
                data.chart_profile.facebook_percentage,
              ]}
              height={360}
              tickStep={20}
              minTick={0}
              maxTick={100}
              displayValuesLikePercents={true}
            />
          </DashboardCardTemplate>
        </Col>
      </Row>

      {/* Complex Chart Section for Various Metrics */}
      {role === Role.Ministry && (
      <DashboardCardTemplate title={t("pointer.overviewPage.complex-diagram")}>
        <div className={`complex-chart-container ${isArab ? "rtl" : ""}`}>
          <MultiLineGraph data={complexFollowersData} />
          <Select
            suffixIcon={<ArrowIcon />}
            selectedValue={isArab ? ComplexChartType_ar[chartType] : chartType}
            setChartType={handleChartTypeChange}
            options={chartOptions}
            className="complex-select"
          />
        </div>
        <Flex align={"center"} gap={32} className={"chart-legend"} style={{ marginTop: 32, direction: isArab ? "rtl" : "ltr" }}>
          {complexFollowersData.datasets.map(it => (
            <Flex gap={10} align={"center"} key={it.label}>
              <div style={{ background: it.borderColor, width: "12px", height: "12px", borderRadius: "50%" }}></div>
              <Typography className={"chart-legend-label"}>{it.label}</Typography>
            </Flex>
          ))}
        </Flex>
      </DashboardCardTemplate>
      )};
    </Flex>
    
  );
};

export { OverviewAllPlatformsSection };
