import React from "react"

import { Flex, Typography } from "antd"
import { useTranslation, Trans } from 'react-i18next';
import { CompactLineGraph } from "src/components/charts/CompactLineGraph"

import { toNumberWithComma } from "src/helpers/toNumberWithComma"

import { PercentageDifference } from "../PercentageDifference"
import "./StatCard.less"
import { useApp } from "../../app"


export type StatCardProps = {
  title: string
  title_ar: string
  number: number
  gainedNumber?: number
}

export const StatCard: React.FC<StatCardProps> = ({ title,title_ar, number, gainedNumber }) => {
  const { isArab, role } = useApp()
  const diff = gainedNumber ? (gainedNumber / (number - gainedNumber)) * 100 : 0
  const { t, i18n } = useTranslation();
  return (
    <Flex vertical className={"stat-card-container"}>
      <Typography.Paragraph type={"secondary"} className={"stat-card-title"}>
      {isArab ? title_ar : title}
      </Typography.Paragraph>
      <Flex align={"center"} justify={"space-between"} gap={12}>
        <div>
          <Typography.Title level={1}>{toNumberWithComma(number || 0)}</Typography.Title>
        </div>
        <span style={{ width: 88, height: 56 }}>
          <CompactLineGraph  />
        </span>
      </Flex>
      
    </Flex>
  )
}
