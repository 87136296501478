import axios, { AxiosResponse } from "axios"
import { chatHeaders } from "./chatUrl"



export const sendChatMessage = (message: string): Promise<AxiosResponse<any>> => {
  return axios.post(
    `/chat`,
    { message },
    {
      headers: {
        ...chatHeaders,
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    }
  )
}