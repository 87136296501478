import { Col, Row } from "antd"
import { FC, MutableRefObject, useState } from "react"
import { SvgIcon } from "../../../assets/icons"
import { useBreakpoints } from "../../../hooks/useBreakpoints"
import { useApp } from "../../app"
import { Container } from "../../ui/Container"
import { CustomBtn } from "../../ui/CustomBtn"
import { Logo } from "../../ui/Logo"
import "./Header.less"
import { AccountDropdown } from "./ui/AccountDropdown"
import { HeaderMenu } from "./ui/HeaderMenu"
import { MobileMenu } from "./ui/MobileMenu"

const Header: FC<{
  overviewRef: MutableRefObject<null>
  detailsRef: MutableRefObject<null>
  rankingRef: MutableRefObject<null>
  generalRef: MutableRefObject<null>
  setIsOnboarding: (isOnboarding: boolean) => void
}> = ({ generalRef, overviewRef, rankingRef, detailsRef, setIsOnboarding }) => {
  const { isDesktop } = useBreakpoints()
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const { isArab } = useApp()

  const handleOpenMenu = () => {
    setIsMenuOpened(true)
  }

  const handleCloseMenu = () => {
    setIsMenuOpened(false)
  }

  return (
    <>
      <Container className={"header"}>
        <Row justify={"space-between"} align={"middle"} style={{ flexDirection: isArab ? "row-reverse" : "row" }}>
          <Col>
            <Logo />
          </Col>
          {isDesktop ? (
            <>
              <Col flex={"auto"}>
                <HeaderMenu
                  detailsRef={detailsRef}
                  generalRef={generalRef}
                  overviewRef={overviewRef}
                  rankingRef={rankingRef}
                />
              </Col>
              <Col style={{ lineHeight: 1 }}>
                <AccountDropdown setIsOnboarding={setIsOnboarding} />
              </Col>
            </>
          ) : (
            <CustomBtn className={"burger-menu-btn"} onClick={handleOpenMenu}>
              <SvgIcon type={"burgerMenu"} width={24} height={24} />
            </CustomBtn>
          )}
        </Row>
      </Container>
      {isMenuOpened && <MobileMenu onClose={handleCloseMenu} setIsOnboarding={setIsOnboarding} />}
    </>
  )
}

export { Header }
