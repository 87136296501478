export const getUserProfile = () => {
  const profileDataLcS = localStorage.getItem("profileInfo")
  const profileData = profileDataLcS ? JSON.parse(profileDataLcS) : ""

  if (!profileData.data?.title) {
    return { title_en: "No user found", title_ar: "", title: "No user found" }
  }

  return {
    title_en: profileData.data?.title_en,
    title_ar: profileData.data?.title_ar,
    title: profileData.data?.title,
  }
}
