import { PropsWithChildren } from "react"

import { Flex, FlexProps } from "antd"
import clsx from "clsx"

import "./index.less"

export const CardContainer: React.FC<PropsWithChildren<FlexProps>> = ({ children, className, ...props }) => {
  return (
    <Flex align={"center"} className={clsx("card-container", className)} {...props}>
      {children}
    </Flex>
  )
}
