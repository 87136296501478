export enum DisplayMode {
  Ministries = "Ministries Locally",
  MinistriesAndOrganizations = "Ministries and Organizations Locally",
  ArabWorld = "Arab world",
  ArabWorldCategory = "Arab world By Category",
}

export enum DisplayMode_ar {
  Ministries = "الوزارات محلياً",
  MinistriesAndOrganizations = "الوزارات والمؤسسات محلياً",
  ArabWorld = "الوطن العربي",
  ArabWorldCategory = "الوطن العربي في نفس المجال",
}

export enum Mode {
  All = "all",
  Select = "select",
}

export enum ComplexChartType {
  Followers = "Followers",
  Likes = "Likes",
  Comments = "Comments",
  Posts = "Posts",
}

export enum ComplexChartType_ar {
  Followers = "متابعين",
  Likes = "الإعجابات",
  Comments = "التعليقات",
  Posts = "المنشورات",
}

export enum Role {
  Ministry = "Ministry",
  PrimeMinister = "Prime Minister",
  Organization = "Organization",
}

export enum Country {
  Kuwait = "Kuwait",
  Others = "Others",
}
