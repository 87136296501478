import { Menu, Typography } from "antd"
import { FC, MutableRefObject, useEffect, useMemo, useState } from "react"
import { Link, useLocation } from "react-router-dom"

import { useApp } from "../../../../app"

import { Trans, useTranslation } from "react-i18next"
import { SvgIcon } from "../../../../../assets/icons"

import "./index.less"

const HeaderMenu: FC<{
  overviewRef: MutableRefObject<null>
  detailsRef: MutableRefObject<null>
  rankingRef: MutableRefObject<null>
  generalRef: MutableRefObject<null>
}> = ({ overviewRef, detailsRef, rankingRef, generalRef }) => {
  const { pathname } = useLocation()
  const [activeKey, setActiveKey] = useState<string>("")
  const { isArab } = useApp()
  const { t } = useTranslation()
  const items = useMemo(
    () => [
      {
        key: "/overview",
        label: (
          <Link
            type={"link"}
            to={"/overview"}
            ref={overviewRef}
            className={"menu-link"}
            style={{ direction: isArab ? "rtl" : "ltr" }}
          >
            <SvgIcon type={"menuChart"} />
            <Typography.Text>{t("pointer.general.header.overview")}</Typography.Text>
          </Link>
        ),
      },
      {
        key: "/detailed-insights",
        label: (
          <Link
            type={"link"}
            to={"/detailed-insights"}
            ref={detailsRef}
            className={"menu-link"}
            style={{ direction: isArab ? "rtl" : "ltr" }}
          >
            <SvgIcon type={"lightBulb"} />
            <Typography.Text>{t("pointer.general.header.detailed-insights")}</Typography.Text>
          </Link>
        ),
      },
      {
        key: "/ranking-and-evaluation",
        label: (
          <Link
            type={"link"}
            to={"/ranking-and-evaluation"}
            ref={rankingRef}
            className={"menu-link"}
            style={{ direction: isArab ? "rtl" : "ltr" }}
          >
            <SvgIcon type={"award"} />
            <Typography.Text>{t("pointer.general.header.ranking-evaluation")}</Typography.Text>
          </Link>
        ),
      },
      {
        key: "/general-info",
        label: (
          <Link
            type={"link"}
            to={"/general-info"}
            ref={generalRef}
            className={"menu-link"}
            style={{ direction: isArab ? "rtl" : "ltr" }}
          >
            <SvgIcon type={"infoCircle"} />
            <Typography.Text>{t("pointer.general.header.general-info")}</Typography.Text>
          </Link>
        ),
      },
    ],
    [overviewRef, detailsRef, rankingRef, generalRef, isArab]
  )

  useEffect(() => {
    const currentItem = items.find(item => pathname.includes(item.key))
    if (currentItem) {
      setActiveKey(currentItem.key)
    }
  }, [pathname, items])

  return <Menu mode={"horizontal"} activeKey={activeKey} items={items} style={{ direction: isArab ? "rtl" : "ltr" }} />
}

export { HeaderMenu }
