import {
  CategoryScale,
  Chart as ChartJS,
  ChartType,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js"

import { useEffect, useMemo, useRef, useState } from "react"
import { Line } from "react-chartjs-2"
import { formatter } from "src/helpers/numberFormatter"
import { useApp } from "../app"

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Filler, Tooltip)

export type MultiLineGraphProps = {
  data: {
    labels: string[]
    datasets: {
      label: string
      borderColor: string
      data: number[]
    }[]
  }
  chartLineOptions?: any
  dataOptions?: any
  chartType?: string
  onChartImageUrlChange?: (newUrl: string | null, chartType: string) => void
}

interface GetImagePluginPluginOptions {
  onChartImageUrlChange?: (newUrl: string) => void
}

declare module "chart.js" {
  interface PluginOptionsByType<TType extends ChartType> {
    getImagePlugin?: GetImagePluginPluginOptions
  }
}

const getImagePlugin = {
  id: "getImagePlugin",
  afterRender: (chart: any, args: any, options: GetImagePluginPluginOptions) => {
    const onChartImageUrlChange = options.onChartImageUrlChange
    if (onChartImageUrlChange) {
      if (chart.updateCount > chart.lastUpdateCount) {
        onChartImageUrlChange(chart.canvas.toDataURL("image/png"))
        chart.lastUpdateCount = chart.updateCount
      }
    }
  },
}

ChartJS.register(getImagePlugin)

export const MultiLineGraph: React.FC<MultiLineGraphProps> = ({
  data,
  chartLineOptions,
  dataOptions,
  chartType,
  onChartImageUrlChange,
}) => {
  const { isArab } = useApp()
  const chartRef = useRef<any>(null)
  const [imageSaved, setImageSaved] = useState(false)

  useEffect(() => {
    const chart = chartRef.current
    if (chart) {
      chart.updateCount = (chart.updateCount || 0) + 1
      chart.lastUpdateCount = chart.updateCount - 1
      chart.update()
    }
    setImageSaved(false)
  }, [data])

  const memoizedData = useMemo(
    () => ({
      labels: data.labels,
      datasets: data.datasets.map(dataset => ({
        ...dataset,
        yAxisID: "y",
        tension: 0.3,
        fill: true,
        backgroundColor: (context: any) => {
          if (!context.chart.chartArea) {
            return
          }

          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart

          const gradienBg = ctx.createLinearGradient(0, top, 0, bottom)

          gradienBg.addColorStop(0, `${dataset.borderColor}44`)
          gradienBg.addColorStop(1, "rgba(255, 255, 255, 0)")

          return gradienBg
        },
      })),
      ...dataOptions,
    }),
    [data, dataOptions]
  )

  return (
    <Line
      ref={chartRef}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          mode: "index",
          intersect: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: true,
            intersect: false,
            backgroundColor: "#fff",
            bodyColor: "#757A89",
            titleColor: "#757A89",
            titleFont: {
              family: "Inter",
              size: 12,
              lineHeight: 1.66,
              weight: 500,
            },
            bodyFont: {
              family: "Inter",
              size: 12,
              lineHeight: 1.66,
              weight: 400,
            },
            cornerRadius: 8,
            callbacks: {
              label: context => {
                if (context.dataset.label?.includes("Outcome")) {
                  return `${context.dataset.label}: -${context.raw}`
                }
                return `${context.dataset.label}: ${context.raw}`
              },
            },
          },
          getImagePlugin: {
            onChartImageUrlChange: url => {
              if (!imageSaved) {
                onChartImageUrlChange && onChartImageUrlChange(url, chartType || "")
                setImageSaved(true)
              }
            },
          },
        },
        scales: {
          x: {
            grid: { color: "#F3F3F5" },
            reverse: isArab,
          },
          yMain: {
            type: "linear",
            display: true,
            position: isArab ? "right" : "left",
            grid: { color: "#F3F3F5" },
            border: { dash: [12] },
            ticks: {
              callback: value => {
                if (value === 0) {
                  return 0
                }

                if (typeof value === "number") {
                  return formatter.format(value)
                }

                return value
              },
            },
          },
          y: {
            display: false,
          },
        },
        ...chartLineOptions,
      }}
      data={memoizedData}
    />
  )
}
