import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from "chart.js"

import { Line } from "react-chartjs-2"

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Filler)

const d = [0, 24, 56, 79]

export const CompactLineGraph: React.FC = () => {
  return (
    <Line
      options={{
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: { display: false },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
      }}
      data={{
        labels: d.map((_, i) => i.toString()),
        datasets: [
          {
            fill: true,
            data: d,
            borderColor: "#C67AF9",
            backgroundColor: context => {
              if (!context.chart.chartArea) {
                return
              }

              const {
                ctx,
                chartArea: { top, bottom },
              } = context.chart

              const gradienBg = ctx.createLinearGradient(0, top, 0, bottom)

              gradienBg.addColorStop(0.2, "#C67AF9")
              gradienBg.addColorStop(1, "rgba(255, 255, 255, 0)")

              return gradienBg
            },
            tension: 0.2,
            borderWidth: 2,
          },
        ],
      }}
    />
  )
}
