import { FC, SVGProps } from "react"
import { ReactComponent as Chart } from "./about/chart.svg"
import { ReactComponent as Cup } from "./about/cup.svg"
import { ReactComponent as Help } from "./about/help.svg"
import { ReactComponent as Medal } from "./about/medal.svg"
import { ReactComponent as ArrowDown } from "./arrow-down.svg"
import { ReactComponent as Arrow } from "./arrow.svg"
import { ReactComponent as BarsSelectorArrow } from "./bars-selector-arrow.svg"
import { ReactComponent as BigArrow } from "./big-arrow.svg"
import { ReactComponent as BurgerMenu } from "./burgerMenu.svg"
import { ReactComponent as Close } from "./close.svg"
import { ReactComponent as Download } from "./download.svg"
import { ReactComponent as Exclamation } from "./forms/exclamation.svg"
import { ReactComponent as EyeOff } from "./forms/eye-off.svg"
import { ReactComponent as Eye } from "./forms/eye.svg"
import { ReactComponent as Info } from "./info.svg"
import { ReactComponent as Logo } from "./logo.svg"
import { ReactComponent as LogoWhite } from "./logo_white.svg"
import { ReactComponent as Award } from "./menu/award.svg"
import { ReactComponent as MenuChart } from "./menu/chart.svg"
import { ReactComponent as InfoCircle } from "./menu/info-circle.svg"
import { ReactComponent as Lightbulb } from "./menu/lightbulb.svg"
import { ReactComponent as AllTabs } from "./platforms/all-tabs.svg"
import { ReactComponent as Facebook } from "./platforms/facebook.svg"
import { ReactComponent as Instagram } from "./platforms/instagram.svg"
import { ReactComponent as X } from "./platforms/x.svg"
import { ReactComponent as Youtube } from "./platforms/youtube.svg"
import { ReactComponent as Engagement } from "./ranking/engagement.svg"
import { ReactComponent as Evaluation } from "./ranking/evaluation.svg"
import { ReactComponent as Followers } from "./ranking/followers.svg"
import { ReactComponent as Posts } from "./ranking/posts.svg"
import { ReactComponent as Ranking } from "./ranking/ranking.svg"
import { ReactComponent as Settings } from "./settings.svg"
import { ReactComponent as Stars } from "./stars.svg"
import { ReactComponent as Countries } from "./stats/countries.svg"
import { ReactComponent as LinearCourseDown } from "./stats/linear-course-down.svg"
import { ReactComponent as LinearCourseUp } from "./stats/linear-course-up.svg"
import { ReactComponent as Ministries } from "./stats/ministries.svg"
import { ReactComponent as NonQualified } from "./stats/non-qualified.svg"
import { ReactComponent as Qualified } from "./stats/qualified.svg"

type SvgIconProps = SVGProps<SVGSVGElement> & { type: keyof typeof icons }

const icons = {
  allTabs: AllTabs,
  arrow: Arrow,
  arrowDown: ArrowDown,
  award: Award,
  barsSelectorArrow: BarsSelectorArrow,
  bigArrow: BigArrow,
  burgerMenu: BurgerMenu,
  chart: Chart,
  cup: Cup,
  close: Close,
  countries: Countries,
  download: Download,
  engagement: Engagement,
  evaluation: Evaluation,
  exclamation: Exclamation,
  eye: Eye,
  eyeOff: EyeOff,
  facebook: Facebook,
  followers: Followers,
  help: Help,
  instagram: Instagram,
  info: Info,
  infoCircle: InfoCircle,
  qualified: Qualified,
  lightBulb: Lightbulb,
  linearCourseDown: LinearCourseDown,
  linearCourseUp: LinearCourseUp,
  logo: Logo,
  logoWhite: LogoWhite,
  medal: Medal,
  menuChart: MenuChart,
  ministries: Ministries,
  nonQualified: NonQualified,
  posts: Posts,
  ranking: Ranking,
  settings: Settings,
  stars: Stars,
  x: X,
  youtube: Youtube,
}

export const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type]

  return <Icon {...svgProps} />
}
