import { Col, Divider, Row, Typography } from "antd"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useApp } from "../../app"

import { ReactComponent as AwardIcon } from "../../../assets/icons/menu/award.svg"
import { ReactComponent as FacebookIcon } from "../../../assets/icons/platforms/facebook.svg"
import { ReactComponent as InstagramIcon } from "../../../assets/icons/platforms/instagram.svg"
import { ReactComponent as XIcon } from "../../../assets/icons/platforms/x.svg"
import { ReactComponent as YoutubeIcon } from "../../../assets/icons/platforms/youtube.svg"
import { ReactComponent as StarsIcon } from "../../../assets/icons/stars.svg"

import { ListItem, PlatformsRanking } from "../../../types/dataTypes"
import { DisplayMode, DisplayMode_ar } from "../../../types/enums"

import { DashboardCardTemplate } from "../DashboardCardTemplate/DashboardCardTemplate"

import "./OverviewRanking.less"

const OverviewRanking: FC<{
  tab: string
  user: string
  title: string
  location?: string
  country?: string
  mode: DisplayMode | DisplayMode_ar; // Updated type
  platformsRanking: PlatformsRanking
  place: number
  totalPlaces: number
  evaluation: number | string
  topPositions: ListItem[]
  allPlatformRender?: boolean | string
}> = ({ tab, user,title, country, mode, platformsRanking, place, totalPlaces, evaluation, topPositions, allPlatformRender = false }) => {

  const { isArab } = useApp()
  const { t, i18n } = useTranslation()

  const tagColor =
  mode === DisplayMode.Ministries || mode === DisplayMode_ar.Ministries
    ? "pink"
    : mode === DisplayMode.MinistriesAndOrganizations || mode === DisplayMode_ar.MinistriesAndOrganizations
    ? "blue"
    : "purple";


const userIndexTitle =
    mode === DisplayMode.Ministries
        ? `The ranking of the ${title} within the Ministries of ${country}`
        : mode === DisplayMode.MinistriesAndOrganizations
        ? `The ranking of the ${title} within the Ministries and Organizations of ${country}`
        : mode === DisplayMode.ArabWorldCategory
        ? `The ranking of the ${title} within the Ministries of The Arab World of the same category`
        : `The ranking of the ${title} within the Ministries of The Arab World`;

const userIndexTitle_ar =
        mode === DisplayMode.Ministries
        ? `ترتيب ${title} مقارنة بـوزارات ${country}`
        : mode === DisplayMode.MinistriesAndOrganizations
        ? `ترتيب ${title} مقارنة بـوزارات ومؤسسات ${country}`
        : mode === DisplayMode.ArabWorldCategory
        ? `ترتيب ${title} مقارنة بـوزارات العالم العربي في نفس المجال`
        : `ترتيب ${title} مقارنة بـوزارات العالم العربي`;

const firstPositionTitle =
        mode === DisplayMode.Ministries
        ? `First Positions - Ministries of ${country}`
        : mode === DisplayMode.MinistriesAndOrganizations
        ? `First Positions - Ministries and Organizations of ${country}`
        : mode === DisplayMode.ArabWorldCategory
        ? `First Positions - Ministries of The Arab World in the same category`
        : `First Positions - Ministries of The Arab World`;

const firstPositionTitle_ar =
        mode === DisplayMode.Ministries
        ? `المراكز الأولى في مؤشر وزارات ${country}`
        : mode === DisplayMode.MinistriesAndOrganizations
        ? `المراكز الأولى في مؤشر وزارات ومؤسسات ${country}`
        : mode === DisplayMode.ArabWorldCategory
        ? `المراكز الأولى في مؤشر وزارات الوطن العربي في نفس المجال`
        : `المراكز الأولى في مؤشر وزارات الوطن العربي`;

  const formatPlace = (number: number | string): string => {
    if (number === undefined) {
      return "Unknown"
    }

    if (number === 11 ?? number === 12) {
      return `${number}th`
    }
    const lastDigit = String(number).slice(-1)
    return `${number}${lastDigit === "1" ? "st" : lastDigit === "2" ? "nd" : lastDigit === "3" ? "rd" : "th"}`
  }

  const getPlace = () => {
    switch (tab) {
      case "All platforms": {
        return formatPlace(place)
      }
      case "Instagram": {
        return formatPlace(platformsRanking.instagram)
      }
      case "Youtube": {
        return formatPlace(platformsRanking.youtube)
      }
      case "X": {
        return formatPlace(platformsRanking.x)
      }
      case "Facebook": {
        return formatPlace(platformsRanking.facebook)
      }
      default: {
        return "-"
      }
    }
  }

  return (
    <Row
      justify={"space-between"}
      className={"overview-ranking-wrapper"}
      gutter={[24, 24]}
      align={"stretch"}
      style={{ direction: isArab ? "rtl" : "ltr" }}
    >
      <Col md={24} lg={12}>
        <DashboardCardTemplate title={isArab ? userIndexTitle_ar : userIndexTitle} style={{ position: 'relative' }}>
          <Typography.Text className={`mode-tag ${tagColor}`}>{mode}</Typography.Text>

          <Row className={"overview-ranking-content-wrapper"} justify={"space-between"} gutter={[8, 24]}>
            <Col xs={24} sm={24} md={allPlatformRender ? 12 : 24} lg={allPlatformRender ? 12 : 24}>
              <div className={allPlatformRender ? "overview-general-ranking-wrapper" : 'overview-general-ranking-wrapper-one-platform'}>
                <div>
                  <Typography.Paragraph className={"overview-general-ranking-label"}>
                    {t("pointer.overviewPage.ranking")}
                  </Typography.Paragraph>
                  <Row align={"middle"} justify={"space-between"} style={{ marginBottom: 6 }}>
                    <Typography.Text className={"overview-general-ranking-value"}>{getPlace()}</Typography.Text>
                    <div className={"overview-general-ranking-icon"}>
                      <AwardIcon />
                    </div>
                  </Row>
                  <Typography.Text className={"overview-general-ranking-text"}>
                    {t("pointer.overviewPage.ministry-num").replace("keyword", totalPlaces.toString())}
                  </Typography.Text>
                </div>
                <Divider />
                <div>
                  <Typography.Paragraph className={"overview-general-ranking-label"}>
                    {t("pointer.overviewPage.evaluation")}
                  </Typography.Paragraph>
                  <Row align={"middle"} justify={"space-between"} style={{ marginBottom: 6 }}>
                    <Typography.Text className={"overview-general-ranking-value"}>{evaluation}</Typography.Text>
                    <div className={"overview-general-ranking-icon"}>
                      <StarsIcon />
                    </div>
                  </Row>
                  <Typography.Text className={"overview-general-ranking-text"}>
                    {t("pointer.overviewPage.ministry-ev")}
                  </Typography.Text>
                </div>

              </div>
            </Col>
            {allPlatformRender && <Col xs={24} sm={24} md={12} lg={12}>
              <Row className={"overview-platforms-ranking-list"} gutter={[0, 28]}>
                <Col className={"list-item"} span={12}>
                  <InstagramIcon className={"platform-icon"} />
                  <Typography.Text>{formatPlace(platformsRanking.instagram)}</Typography.Text>
                </Col>
                <Col className={"list-item"} span={12}>
                  <FacebookIcon className={"platform-icon"} />
                  <Typography.Text>{formatPlace(platformsRanking.facebook)}</Typography.Text>
                </Col>
                <Col className={"list-item"} span={12}>
                  <YoutubeIcon className={"platform-icon"} />
                  <Typography.Text>{formatPlace(platformsRanking.youtube)}</Typography.Text>
                </Col>
                <Col className={"list-item"} span={12}>
                  <XIcon className={"platform-icon"} />
                  <Typography.Text>{formatPlace(platformsRanking.x)}</Typography.Text>
                </Col>
              </Row>
            </Col>}
          </Row>
        </DashboardCardTemplate>
      </Col>
      <Col md={24} lg={12}>
        <DashboardCardTemplate title={isArab ? firstPositionTitle_ar : firstPositionTitle}>
          <Typography.Text className={`mode-tag ${tagColor}`}>{mode}</Typography.Text>
          <Row className={"overview-ranking-content-wrapper overview-ranking-positions-wrapper ranking-ministries"} justify={"space-between"} gutter={[48, 24]}>
            {topPositions.slice(0, 3).map(position => (
              <Col className={"ministry-block"} key={position.title} xs={24} sm={24} md={8}>
                <img
                  src={typeof position.src === "string" ? position.src : ""}
                  alt={position.description}
                  className={"overview-general-ranking-image"}
                />
                <Typography.Paragraph
                  className={`overview-general-ranking-place ${position.uniqueId === user && "active"}`}
                >
                
                  {/* {formatPlace(position.title)} */}
                  {formatPlace(position.ranking || 0)}
                  
              
                
                
                </Typography.Paragraph>
                <Typography.Text
                  className={`overview-general-ranking-user ${position.uniqueId === user && "active"}`}
                >
                  {position.description} 
                  
                </Typography.Text>
                <Typography.Text
                  className={`overview-general-ranking-country ${position.uniqueId === user && "active"}`}
                >
                  <br></br>
                  {position.country}
                  
                </Typography.Text>
              </Col>
            ))}
          </Row>
        </DashboardCardTemplate>
      </Col>
    </Row>
  )
}

export { OverviewRanking }
