import { Draft, Immutable, produce } from "immer";
import { create } from "zustand";
import { Role } from "src/types/enums";
import { MultiLineGraphProps } from "src/components/charts/MultiLineGraph";
import { BarsGraphProps } from "src/components/charts/BarsGraph";
import { ProgressData } from "src/types/dataTypes";


interface ApiDataset {
  facebook_profile: { [key: string]: any } | [] | null | undefined;
  instagram_profile: { [key: string]: any } | [] | null | undefined;
  youtube_profile: { [key: string]: any } | [] | null | undefined;
  twitter_profile: { [key: string]: any } | [] | null | undefined;
}

interface ApiMinistry extends ApiDataset {
  id: number;
  title: string;
  category: string;
  sub_category: string;
  country: string;
  image: string;
}

interface RawResponse extends ApiDataset {
  all_profiles: ApiMinistry[];
}

type ProfileDataset = {
  followers: MultiLineGraphProps["data"] | null;
  followers_gained: MultiLineGraphProps["data"] | null;
  likes: MultiLineGraphProps["data"] | null;
  comments: MultiLineGraphProps["data"] | null;
  engagement: MultiLineGraphProps["data"] | null;
  engagement_days: ProgressData[] | null;
  best_time: BarsGraphProps["data"] | null;
  impressions_and_reach: BarsGraphProps["data"] | null;
  activity: MultiLineGraphProps["data"] | null;
  views: MultiLineGraphProps["data"] | null;
} | null;

type MinistryDataset = {
  instagram?: ProfileDataset;
  facebook?: ProfileDataset;
  youtube?: ProfileDataset;
  x?: ProfileDataset;
} | null;

type MinistryData = ApiMinistry & MinistryDataset;

type InsightState = {
  ministries: MinistryData[];
  profileTabs: { key: number; label: string; disable: boolean }[];
  activeProfileTab: number;
  rawResponse: RawResponse | null;
  dataset: MinistryDataset | null;
};

type InsightActions = {
  set: (cb: (store: Draft<InsightState>) => void) => void;
  buildDatasets: (role: Role, tab: string, t: (key: string) => string) => void;
};

export type InsightStore = Immutable<InsightState & InsightActions>;

function profileDataset(data: any, tab: string, t: (key: string) => string): ProfileDataset {
  let profileKey: string;
  if (tab === "X") {
    profileKey = "twitter_profile";
  } else {
    profileKey = tab.toLowerCase() + "_profile";
  }

  if (!data[profileKey]) {
    return null;
  }
  if (Array.isArray(data[profileKey]) && data[profileKey].length === 0) {
    return null;
  }

  const date_series = data[profileKey].date_series.map((d: string) =>
    new Date(d).toLocaleDateString("en-US", { month: "short", day: "numeric" })
  );

  // followers chart
  const followers: MultiLineGraphProps["data"] = {
    labels: date_series,
    datasets: [],
  };

  switch (tab) {
    case "Youtube":
      followers.datasets = [
        {
          label: t("pointer.labels.subscribers"),
          borderColor: "#4480FF",
          data: data[profileKey].subscribers.subscriber_series.map(Number),
        },
      ];
      break;
    default:
      followers.datasets = [
        {
          label: t("pointer.labels.followers"),
          borderColor: "#4480FF",
          data: data[profileKey].followers.followers_series.map(Number),
        },
      ];
  }

  let followers_gained: MultiLineGraphProps["data"] | null = null;

  switch (tab) {
    case "Youtube":
      break;
    default:
      followers_gained = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.followersGained"),
            borderColor: "#FF6AC7",
            data: data[profileKey].followers.followers_gained_series.map(Number),
          },
        ],
      };
  }

  // likes chart
  let likes: MultiLineGraphProps["data"] | null = null;

  switch (tab) {
    case "Facebook":
      likes = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.likes"),
            borderColor: "#C67AF9",
            data: data[profileKey].brand_posts.likes_series.map(Number),
          },
        ],
      };
      break;
    case "Instagram":
      likes = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.likes"),
            borderColor: "#C67AF9",
            data: data[profileKey].brand_post.likes_series.map(Number),
          },
        ],
      };
      break;
    case "Youtube":
      likes = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.likes"),
            borderColor: "#C67AF9",
            data: data[profileKey].likes.likes_series.map(Number),
          },
        ],
      };
      break;
    case "X":
      break;
    default:
      break;
  }

  // engagement rate line chart
  let engagement: MultiLineGraphProps["data"] | null = null;

  switch (tab) {
    case "Facebook":
      engagement = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.engagement"),
            borderColor: "#4480FF",
            data: data[profileKey].brand_posts.engagement_series.map(Number),
          },
        ],
      };
      break;
    case "Instagram":
      engagement = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.engagement"),
            borderColor: "#4480FF",
            data: data[profileKey].brand_post.engagement_series.map(Number),
          },
        ],
      };
      break;
    case "Youtube":
      break;
    case "X":
      engagement = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.engagement"),
            borderColor: "#4480FF",
            data: data[profileKey].followers.engagement_series.map(Number),
          },
        ],
      };
      break;
    default:
      break;
  }

  // comments chart
  let comments: MultiLineGraphProps["data"] | null = null;

  switch (tab) {
    case "Facebook":
      comments = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.comments"),
            borderColor: "#FF59C0",
            data: data[profileKey].brand_posts.comments_series.map(Number),
          },
        ],
      };
      break;
    case "Instagram":
      comments = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.comments"),
            borderColor: "#FF59C0",
            data: data[profileKey].brand_post.comments_series.map(Number),
          },
        ],
      };
      break;
    case "Youtube":
      comments = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.comments"),
            borderColor: "#FF59C0",
            data: data[profileKey].comments.comments_series.map(Number),
          },
        ],
      };
      break;
    case "X":
      comments = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.retweets"),
            borderColor: "#FF59C0",
            data: data[profileKey].retweets.retweet_series.map(Number),
          },
        ],
      };
      break;
    default:
      break;
  }

// Define day labels in both English and Arabic, starting with Sunday
const dayLabels = {
  en: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  ar: ["الأحد", "الإثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"]
};


  // engagement by days chart
  let engagement_days: ProgressData[] | null = null;
  if (tab !== "Youtube") {
    engagement_days = data[profileKey].timing.day_label_series.map((day: string, idx: number) => {
      const dayIndex = dayLabels.en.indexOf(day); // Find the index of the day in English labels
      return {
        label: day, // English label
        label_ar: dayLabels.ar[dayIndex], // Arabic label
        value: data[profileKey].timing.day_engagement_series[idx], // Engagement value
      };
    });
  }

  // best time to post chart
  let best_time: BarsGraphProps["data"] | null = null;

  switch (tab) {
    case "Facebook":
    case "Instagram":
      best_time = {
        labels: data[profileKey].timing.time_label_series,
        datasets: [
          {
            label: t("pointer.labels.bestTime"),
            color: "#4480FF",
            data: data[profileKey].timing.time_engagement_series.map(Number),
          },
        ],
      };
      break;
    case "X":
      best_time = {
        labels: data[profileKey].timing.time_series,
        datasets: [
          {
            label: t("pointer.labels.bestTime"),
            color: "#4480FF",
            data: data[profileKey].timing.time_engagement_series.map(Number),
          },
        ],
      };
      break;
    default:
      break;
  }

  // impressions and reach chart
  let impressions_and_reach: BarsGraphProps["data"] | null = null;
  switch (tab) {
    case "Facebook":
      impressions_and_reach = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.impressions"),
            color: "#4480FF",
            data: data[profileKey].brand_posts.impression_series.map(Number),
          },
          {
            label: t("pointer.labels.reach"),
            color: "#FF6AC7",
            data: data[profileKey].brand_posts.reach_series.map(Number),
          },
        ],
      };
      break;
    case "Instagram":
      impressions_and_reach = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.impressions"),
            color: "#4480FF",
            data: data[profileKey].brand_post.impression_series.map(Number),
          },
          {
            label: t("pointer.labels.reach"),
            color: "#FF6AC7",
            data: data[profileKey].brand_post.reach_series.map(Number),
          },
        ],
      };
      break;
    default:
      break;
  }

  // activity chart
  let activity: MultiLineGraphProps["data"] | null = null;

  switch (tab) {
    case "Facebook":
      activity = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.posts"),
            borderColor: "#4480FF",
            data: data[profileKey].brand_posts.posts_series.map(Number),
          },
        ],
      };
      break;
    case "Instagram":
      activity = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.posts"),
            borderColor: "#4480FF",
            data: data[profileKey].brand_post.posts_series.map(Number),
          },
        ],
      };
      break;
    case "X":
      activity = {
        labels: date_series,
        datasets: [
          {
            label: t("pointer.labels.tweets"),
            borderColor: "#4480FF",
            data: data[profileKey].tweet_type.posts_series.map(Number),
          },
        ],
      };
      break;
    default:
      break;
  }

  // views chart
  let views: MultiLineGraphProps["data"] | null = null;
  if (tab === "Youtube") {
    views = {
      labels: date_series,
      datasets: [
        {
          label: t("pointer.labels.views"),
          borderColor: "#FF6AC7",
          data: data[profileKey].views.views_series.map(Number),
        },
      ],
    };
  }

  return {
    followers,
    followers_gained,
    likes,
    engagement,
    comments,
    engagement_days,
    best_time,
    impressions_and_reach,
    activity,
    views,
  };
}


export const useInsightStore = create<InsightStore>((setStore, getStore) => ({
  set: (cb) => setStore(produce<InsightStore>(cb)),
  buildDatasets: (role, tab, t) => {
    const profileTabs = getStore().profileTabs;
    const data = getStore().rawResponse;
    if (!data) {
      return;
    }

    if (role === Role.Ministry || role === Role.Organization) {
      const ministry = {
        instagram: profileDataset(data, "Instagram", t),
        facebook: profileDataset(data, "Facebook", t),
        youtube: profileDataset(data, "Youtube", t),
        x: profileDataset(data, "X", t),
      };

      getStore().set((store) => {
        store.dataset = ministry;
      });
    } else if (role === Role.PrimeMinister && data.all_profiles.length) {
      const ministries = data.all_profiles.map((ministry) => ({
        ...ministry,
        instagram: profileDataset(ministry, "Instagram", t),
        facebook: profileDataset(ministry, "Facebook", t),
        youtube: profileDataset(ministry, "Youtube", t),
        x: profileDataset(ministry, "X", t),
      }));

      getStore().set((store) => {
        store.ministries = ministries;
      });
    }
  },
  profileTabs: [
    {
      key: 0,
      label: "Instagram",
      disable: false,
    },
    {
      key: 1,
      label: "Facebook",
      disable: false,
    },
    {
      key: 2,
      label: "Youtube",
      disable: false,
    },
    {
      key: 3,
      label: "X",
      disable: false,
    },
  ],
  activeProfileTab: 0,
  rawResponse: null,
  ministries: [],
  dataset: null,
}));
