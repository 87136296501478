import { Row, Col,Divider, Typography, Space } from "antd"
import { FC } from "react"
import { useTranslation } from 'react-i18next';
import { useApp } from "../../app"
import { Logo } from "../../ui/Logo"
import { LinkedinFilled, TwitterOutlined, InstagramOutlined, FacebookFilled } from '@ant-design/icons';

import "./Footer.less"

const Footer: FC = () => {
  const { isArab } = useApp()
  const { t } = useTranslation();

  return (
    <div className={"container landing"}>
      <Row justify={"space-between"} align="middle" className={"footer-wrapper"} style={{ direction: isArab ? "rtl" : "ltr" }}>
        <Col>
          <Logo place={"footer"} width={122} height={40} />
        </Col>
        <Col>
          <Space size="large">
            <a href="https://www.linkedin.com/company/alsabahaward/" target="_blank" rel="noopener noreferrer">
              <LinkedinFilled style={{ fontSize: '24px', color: '#fff' }} />
            </a>
            <a href="https://twitter.com/AlSabah_Award" target="_blank" rel="noopener noreferrer">
              <TwitterOutlined style={{ fontSize: '24px', color: '#fff' }} />
            </a>
            <a href="https://www.instagram.com/alsabahaward/" target="_blank" rel="noopener noreferrer">
              <InstagramOutlined style={{ fontSize: '24px', color: '#fff' }} />
            </a>
            <a href="https://www.facebook.com/alsabahaward" target="_blank" rel="noopener noreferrer">
              <FacebookFilled style={{ fontSize: '24px', color: '#fff' }} />
            </a>
          </Space>

        </Col>
      </Row>
      <Divider />
      <Typography.Text className={"footer-rights"} style={{ textAlign: isArab ? "end" : "start" }}>
      {t('pointer.general.footer.right_full')}
      </Typography.Text>
    </div>
  )
}

export { Footer }