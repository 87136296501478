import { Button, Flex, Input, List, Modal, Typography } from "antd"
import { FC, useEffect, useRef, useState } from "react"
import { ReactComponent as CloseIcon } from "../../../assets/icons/x-close.svg"
import { SendOutlined, RobotOutlined, MessageOutlined } from '@ant-design/icons'
import { useApp } from "../../app"
import "./AIChat.less"
import chatbotIcon from "/public/chatbot.png";

import { sendChatMessage } from "src/api/sendChatMessage"

const { TextArea } = Input

const AIChat: FC<{ open?: boolean; handleClose: () => void }> = ({ open, handleClose }) => {
  const { isArab } = useApp()
  const [messages, setMessages] = useState<Array<{ text: string; sender: "user" | "ai" }>>([])
  const [inputValue, setInputValue] = useState("")
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const [isTyping, setIsTyping] = useState(false)

  const handleSend = async () => {
    if (inputValue.trim()) {
      setMessages(prev => [...prev, { text: inputValue, sender: "user" }])
      setInputValue("")
      setIsTyping(true)

      try {
        const response = await sendChatMessage(inputValue)
        setIsTyping(false)  
        setMessages(prev => [...prev, { text: response.data.message, sender: "ai" }])
        console.log("AI response:", response.data)
      } catch (error) {
        console.error("Error sending message:", error)
        setIsTyping(false)  
        setMessages(prev => [...prev, { text: "Sorry, I couldn't process your request.", sender: "ai" }])
      }
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSend()
    }
  }

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [messages, isTyping])

  console.log("Messages:", messages)

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      footer={null}
      closeIcon={<CloseIcon />}
      className={`ai-chat ${isArab && "rtl"}`}
      width={400}
      style={{ direction: isArab ? "rtl" : "ltr" }}
    >
      <Flex vertical gap={36} style={{ height: "500px" }}>
        <Flex align={"center"} gap={8}>
        <img src={chatbotIcon} alt={"Chatbot"} style={{ width: '36px', height: '36px' }} />
          <Typography.Title level={4}>Pointer AI Chat</Typography.Title>
        </Flex>
        {messages.length === 0 ? (
          <Flex vertical align={"center"} justify={"center"} style={{ height: '450px', border: '1px solid #d9d9d9', borderRadius: '12px', padding: '10px' }}>
            <MessageOutlined style={{ fontSize: '36px', color: '#4480FF', marginBottom: '16px' }} />
            <Typography.Text>Start chatting now!</Typography.Text>
          </Flex>
        ) : (
          <List
            className={"chat-messages"}
            style={{ overflowY: "auto", flexGrow: 1, height: '350px', border: '1px solid #f0f5ff', borderRadius: '12px', padding: '10px' }}
            dataSource={messages}
            renderItem={item => (
              <List.Item className={`message ${item.sender}`}>
                <Typography.Text>{item.text}</Typography.Text>
              </List.Item>
            )}
          >
            {isTyping && (
              <List.Item className={"message ai typing"}>
                <Typography.Text>.</Typography.Text>
              </List.Item>
            )}
            <div ref={messagesEndRef} />
          </List>
        )}
        <Flex align={"flex-end"}>
          <TextArea
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder={"Type your message here..."}
            autoSize={{ minRows: 1, maxRows: 4 }}
            style={{ flexGrow: 1, marginRight: 8 }}
          />
          <Button 
            type={"primary"} 
            icon={<SendOutlined />} 
            onClick={handleSend} 
            disabled={isTyping}
            style={{ minHeight: 32, width: 32, padding: 16 }}
          />
        </Flex>
      </Flex>
    </Modal>
  )
}

export { AIChat }