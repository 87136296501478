import { Col, Divider, Flex, Image, Row, Typography } from "antd"; // Importing Ant Design components for layout, typography, and images
import { FC, useEffect, useState } from "react"; // Importing React's Functional Component type and hooks for state and effect
import { Trans, useTranslation } from "react-i18next"; // Importing translation hooks for internationalization
import { SvgIcon } from "../../../assets/icons"; // Importing SVG icon component
import { useBreakpoints } from "../../../hooks/useBreakpoints"; // Importing custom hook for handling responsive breakpoints
import { App } from "../../../types"; // Importing app-specific types
import { DisplayMode, DisplayMode_ar, Role } from "../../../types/enums"; // Importing enums for display modes and roles
import { useApp } from "../../app"; // Importing custom hook to access app-specific state
import { ExpandedRankingCardContent } from "../../ui"; // Importing custom UI component for expanded content
import { ContentBox } from "../../ui/ContentBox"; // Importing custom UI component for content boxes
import { CustomBtn } from "../../ui/CustomBtn"; // Importing custom button component
import "./index.less"; // Importing component-specific styles

// Defining the interface for the component props
interface RankingMinistriesProps {
  tagText: string; // Tag text to display (e.g., "Ministries")
  isStats?: boolean; // Flag to determine if stats should be displayed
  ministriesData: App.MinistryData[] | null; // Data for ministries
  role: Role | null; // Role of the current user
  user: string; // The current user's ministry profile
  totalCountries: number | null | undefined; // Total number of countries (for rankings)
  tab: string; // The selected tab/platform (e.g., Facebook, Instagram)
}

// Main component for displaying ranking of ministries
export const RankingMinistries: FC<RankingMinistriesProps> = ({
  tagText,
  isStats = false, // Default value for isStats is false
  role,
  user,
  ministriesData,
  totalCountries,
  tab,
}) => {
  const { isArab } = useApp(); // Accessing isArab from app context to determine language direction
  const { t, i18n } = useTranslation(); // Translation hook for internationalization
  const { isMobile, isTablet, isDesktop, isMD } = useBreakpoints(); // Responsive breakpoints for different screen sizes

  // State for the currently selected ministry
  const [currentSelectedMinistry] = useState<App.MinistryData | null>(() => {
    const ministry = ministriesData && ministriesData.find(it => it.profile === user); // Finding the ministry that matches the current user
    return ministry || null;
  });

  // State for the number of visible ministries based on screen size
  const [visibleMinistries, setVisibleMinistries] = useState(
    isDesktop ? 10 : isTablet ? 8 : isMD ? 6 : 4
  );

  // Flags to determine if the card is expanded and if it should display in one row
  const isExpandedCard = role === Role.PrimeMinister;
  const isOneRow = role === Role.PrimeMinister;

  // Object to define how many cards are visible based on screen size and role
  const cardsVisible = {
    desktop: isOneRow ? 5 : 10,
    tablet: !isExpandedCard ? 8 : isOneRow ? 3 : 6,
    md: !isExpandedCard ? 6 : isOneRow ? 2 : 4,
    min: !isExpandedCard ? 4 : 2,
  };

  // useEffect hook to update the number of visible ministries when the screen size changes
  useEffect(() => {
    setVisibleMinistries(
      isDesktop ? cardsVisible.desktop : isTablet ? cardsVisible.tablet : isMD ? cardsVisible.md : cardsVisible.min
    );
  }, [isMobile, isTablet, isDesktop, isMD]);

  // Function to add the appropriate ordinal suffix to a number (e.g., 1st, 2nd, 3rd)
  const getOrdinalSuffix = (num: number) => {
    let suffix = "th";
    if (num % 10 === 1 && num % 100 !== 11) {
      suffix = "st";
    } else if (num % 10 === 2 && num % 100 !== 12) {
      suffix = "nd";
    } else if (num % 10 === 3 && num % 100 !== 13) {
      suffix = "rd";
    }
    return `${num}${suffix}`;
  };

  // Handler for the "View More" button to show more ministries
  const handleViewMore = () => {
    setVisibleMinistries(prev => prev + visibleMinistries);
  };

  // Function to get statistics for a ministry based on the selected tab and stat type
  const getStats = (ministry: any | null, statsType: "Engagement" | "Posts" | "Fans" | "Overall" | "Rank") => {
    if (!ministry) {
      return '';
    }
  
    // Function to get the specific stat based on the platform/tab
    const getStatByTab = (tab: string, statsType: string) => {
      switch (tab) {
        case "Facebook":
          switch (statsType) {
            case "Engagement":
              return ministry.facebook_engagement_points;
            case "Posts":
              return ministry.facebook_post_points;
            case "Fans":
              return ministry.facebook_follower_points;
            case "Overall":
              return ministry.Facebook_total_score;
            case "Rank":
              return ministry.Facebook_rank;
            default:
              return 0;
          }
        case "Instagram":
          switch (statsType) {
            case "Engagement":
              return ministry.instagram_engagement_points;
            case "Posts":
              return ministry.instagram_post_points;
            case "Fans":
              return ministry.instagram_follower_points;
            case "Overall":
              return ministry.Instagram_total_score;
            case "Rank":
              return ministry.Instagram_rank;
            default:
              return 0;
          }
        case "Youtube":
          switch (statsType) {
            case "Engagement":
              return ministry.youtube_engagement_points;
            case "Posts":
              return ministry.youtube_post_points;
            case "Fans":
              return ministry.youtube_follower_points;
            case "Overall":
              return ministry.Youtube_total_score;
            case "Rank":
              return ministry.Youtube_rank;
            default:
              return 0;
          }
        case "X":
          switch (statsType) {
            case "Engagement":
              return ministry.twitter_engagement_points;
            case "Posts":
              return ministry.twitter_post_points;
            case "Fans":
              return ministry.twitter_follower_points;
            case "Overall":
              return ministry.Twitter_total_score;
            case "Rank":
              return ministry.Twitter_rank;
            default:
              return 0;
          }
        default:
          switch (statsType) {
            case "Engagement":
              return ministry.overall_engagement_score;
            case "Posts":
              return ministry.overall_post_score;
            case "Fans":
              return ministry.overall_followers_score;
            case "Overall":
              return ministry.overall_rank_score;
            case "Rank":
              return ministry.overall_rank;
            default:
              return 0;
          }
      }
    };
  
    const result = getStatByTab(tab, statsType); // Get the specific stat
  
    const formattedResult = typeof result === 'number' ? result.toFixed(2) : result; // Format the result to two decimal places if it's a number
  
    if (statsType === "Rank") {
      const rankDisplay = result ? getOrdinalSuffix(result) : "Unknown"; // Add ordinal suffix to the rank
      return rankDisplay;
    }
  
    return formattedResult; // Return the formatted result
  };
  

  // Array of stats to display with their titles and values
  const stats = [
    {
      title: "Ranking",
      title_ar: "المركز",
      value: getStats(currentSelectedMinistry, "Rank") || "Unknown",
      description: `out of ${totalCountries}`,
      iconType: "ranking",
    },
    {
      title: "Evaluation",
      title_ar: "التقييم",
      value: getStats(currentSelectedMinistry, "Overall") || "Unknown",
      description: `out of 100`,
      iconType: "evaluation",
    },
    {
      title: "Engagement",
      title_ar: "التفاعلات",
      value: getStats(currentSelectedMinistry, "Engagement") || "Unknown",
      description: `out of 33.33`,
      iconType: "engagement",
    },
    {
      title: "Posts",
      title_ar: "المنشورات",
      value: getStats(currentSelectedMinistry, "Posts") || "Unknown",
      description: `out of 33.33`,
      iconType: "posts",
    },
    {
      title: "Followers",
      title_ar: "المتابعين",
      value: getStats(currentSelectedMinistry, "Fans") || "Unknown",
      description: `out of 33.33`,
      iconType: "followers",
    },
  ];

  // Determine the tag color based on the display mode
  const tagColor =
    tagText === DisplayMode.Ministries || tagText === DisplayMode_ar.Ministries
      ? "pink"
      : tagText === DisplayMode.MinistriesAndOrganizations || tagText === DisplayMode_ar.MinistriesAndOrganizations
      ? "blue"
      : "purple";

  // Find the index of the current ministry
  const currentMinistryIndex = ministriesData?.findIndex(it => it.profile === user);

  // Get the data for the current ministry
  const currentMinistry = ministriesData?.find(it => it.profile === user);

  // Slice the ministries data based on the number of visible ministries
  const slicedData = ministriesData?.slice(0, visibleMinistries);

  // Slice the data again to prepare for adding the current ministry if necessary
  const shortSlicedData = slicedData?.slice(0, visibleMinistries - 1);

  // Determine if the current ministry is being displayed
  const isCurrentMinistry = role !== Role.PrimeMinister;

  // Check if the array should be modified to include the current ministry
  const shouldModifyArray = currentMinistryIndex && visibleMinistries < currentMinistryIndex + 1 && isCurrentMinistry;

  // Modify the array if necessary
  shouldModifyArray && currentMinistry && shortSlicedData?.push(currentMinistry);

  // Determine the data to be shown in the UI
  const shownData = shouldModifyArray ? shortSlicedData : slicedData;

  return (
    <ContentBox className={`ranking-ministries ${isExpandedCard && "expanded"}`}>
      {/* Header section with title and tag */}
      <Flex
        align={!isMobile && !isExpandedCard ? "center" : isArab ? "flex-end" : "flex-start"} // Align based on screen size and language direction
        justify={ isArab ? "flex-end" : "flex-start"} // Justify based on language direction
        gap={8}
        vertical={isMobile || isExpandedCard} // Stack vertically on mobile or if the card is expanded
      >
        <Typography.Title level={2} className={"box-title"}>
        </Typography.Title>
        <Typography.Text className={`mode-tag ${tagColor}`}>
          {t(`${tagText}`)} {/* Tag text indicating the mode */}
        </Typography.Text>
      </Flex>

      {/* Conditional rendering of stats if isStats is true */}
      {isStats && (
        <Row className={"stats"} justify={"space-between"} style={{ direction: isArab ? "rtl" : "ltr" }}>
          {stats.map((stat, index) => (
            <Col key={index} className={"stats-block"}>
              <Typography.Paragraph className={"stats-title"}>
                {isArab ? stat.title_ar : stat.title} {/* Display title in Arabic or English */}
              </Typography.Paragraph>
              <Row justify={"space-between"} align={"middle"} style={{ marginTop: isMobile ? 16 : 24 }}>
                <Typography.Paragraph className={"stats-place"}>
                  {stat.value} {/* Display the stat value */}
                </Typography.Paragraph>
                <SvgIcon type={stat?.iconType as any} width={40} height={40} className={"stats-icon"} />
              </Row>
              <Typography.Paragraph className={"stats-description"}>
                {stat.description} {/* Display the stat description */}
              </Typography.Paragraph>
            </Col>
          ))}
        </Row>
      )}

      {/* Ministries wrapper */}
      <Row
        className={`ministries-wrapper ${isExpandedCard && "expanded"}`}
        style={{
          direction: isArab ? "rtl" : "ltr",
          backgroundColor: role === "Prime Minister" ? "transparent" : "#FAFBFF",
        }}
      >
        {/* Loop through the ministries to display each one */}
        {shownData?.map(ministry => (
          <Flex
            vertical
            className={
              isCurrentMinistry && currentSelectedMinistry?.id === ministry?.id
                ? "ministry-block active"
                : "ministry-block"
            } // Highlight the current ministry
            key={ministry.profile}
            style={{ direction: isArab ? "rtl" : "ltr" }}
          >
            <Image
              rootClassName={"ministry-img-root"}
              className={"ministry-img"}
              src={ministry?.p_avatar}
              alt={"Ministry"}
              preview={false}
              style={{
                width: role === "Prime Minister"  ? 100 : 80,
                height: role === "Prime Minister"  ? 100 : 80,
              }} // Adjust image size based on role
            />
            <Typography.Paragraph className={"ministry-place"}>
              {(getStats(ministry, "Rank"))} {/* Display the ministry's rank */}
            </Typography.Paragraph>
            <Typography.Paragraph className={"ministry-name"}>
              {ministry?.profile} {/* Display the ministry's name */}
            </Typography.Paragraph>
            {/* Expanded card content, shown if isExpandedCard is true */}
            {isExpandedCard && (
              <div className={"expanded-block-content-wrapper"} style={{ backgroundColor: "#FAFBFF", borderRadius: 8 }}>
                <ExpandedRankingCardContent
                  label={t("pointer.labels.evaluation")} // Translate "Evaluation" label
                  value={getStats(ministry, "Overall") || "Unknown"}
                  outOf={"100"}
                />
                <Divider />
                <ExpandedRankingCardContent
                  label={t("pointer.labels.engagement")} // Translate "Engagement" label
                  value={getStats(ministry, "Engagement")}
                  outOf={"33.33"}
                />
                <ExpandedRankingCardContent
                  label={t("pointer.labels.posts")} // Translate "Posts" label
                  value={getStats(ministry, "Posts")}
                  outOf={"33.33"}
                />
                <ExpandedRankingCardContent
                  label={t("pointer.labels.followers")} // Translate "Followers" label
                  value={getStats(ministry, "Fans")}
                  outOf={"33.33"}
                />
              </div>
            )}
          </Flex>
        ))}
      </Row>
      {/* View More button to load more ministries */}
      {ministriesData && visibleMinistries < ministriesData?.length && (
        <CustomBtn className={"view-more-btn"} onClick={handleViewMore}>
          {t("pointer.general.btn.view-more")}
        </CustomBtn>
      )}
    </ContentBox>
  );
};
