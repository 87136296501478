import { CSSProperties, FC } from "react"
import { Link } from "react-router-dom"
import { SvgIcon } from "../../../assets/icons"

interface LogoProps {
  width?: number | string
  height?: number | string
  isLink?: boolean
  style?: CSSProperties
  place?: "header" | "footer"
}

const Logo: FC<LogoProps> = ({ width, height, isLink = true, style, place = "header", ...props }) => {
  return isLink ? (
    <Link to={"/"} {...props} style={{ display: "flex", ...style }}>
      <SvgIcon type={place === "header" ? "logo" : "logoWhite"} width={width || 67} height={height || 22} />
    </Link>
  ) : (
    <SvgIcon
      type={place === "header" ? "logo" : "logoWhite"}
      width={width || 67}
      height={height || 22}
      style={style}
      {...props}
    />
  )
}

export { Logo }
