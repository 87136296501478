import { Button, Col, Dropdown, Flex, Image, MenuProps, Switch, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../../../api/logout";
import { SvgIcon } from "../../../../../assets/icons";
import { Role } from "../../../../../types/enums";
import { useApp } from "../../../../app";
import "./index.less";

export const AccountDropdown: FC<{ setIsOnboarding: (isOnboarding: boolean) => void }> = ({ setIsOnboarding }) => {
  const { isArab, setIsArab, role } = useApp();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // State to track profile info validity
  const [profileInfo, setProfileInfo] = useState<any>(null);
  const [isProfileChecked, setIsProfileChecked] = useState(false);

  // Check profile info on component mount
  useEffect(() => {
    const storedProfileInfo = JSON.parse(localStorage.getItem("profileInfo") || "{}");

    if (!storedProfileInfo.data) {
    } else {
      setProfileInfo(storedProfileInfo);
      setIsProfileChecked(true);
    }
  }, [navigate]);

  const handleArabClick = () => {
    const changedLang = !isArab;
    i18n.changeLanguage(changedLang ? "ar" : "en");
    localStorage.setItem("arab", JSON.stringify(!isArab));
    setIsArab(prevState => !prevState);
  };

  if (!isProfileChecked) {
    return null; // Or a loading spinner if you prefer
  }

  const items: MenuProps["items"] = [
    {
      label: (
        <Button
          type={"link"}
          onClick={() => setIsOnboarding(true)}
          className={"account-dropdown-btn"}
          style={{ direction: isArab ? "rtl" : "ltr" }}
        >
          {t("pointer.general.btn.watch-tutorial")}
        </Button>
      ),
      key: "0",
    },
    {
      label: (
        <Button
          type={"link"}
          className={"account-dropdown-btn with-switch"}
          style={{ direction: isArab ? "rtl" : "ltr" }}
          onClick={handleArabClick}
        >
          {!isArab ? (
            <>
              Arabic <Switch checked={isArab} size={"small"} />
            </>
          ) : (
            <>
              انجليزي <Switch checked={isArab} size={"small"} />
            </>
          )}
        </Button>
      ),
      key: "1",
    },
    {
      label: (
        <Button
          type={"link"}
          className={"account-dropdown-btn"}
          style={{ direction: isArab ? "rtl" : "ltr" }}
          onClick={() =>
            logout()
              .then(() => navigate("/login"))
              .catch(e => console.log(e))
          }
        >
          {t("pointer.general.btn.logout")}
        </Button>
      ),
      key: "2",
    },
  ];

  return (
    <Dropdown className={"account-dropdown"} menu={{ items }} trigger={["click"]}>
      <Flex style={{ direction: isArab ? "rtl" : "ltr" }} align={"center"} gap={7}>
        <Col>
          <Image preview={false} src={profileInfo?.data?.image} alt={"Avatar"} width={36} height={36} />
        </Col>
        <Col>
          <Typography.Paragraph className={"user-name"}>
            {isArab ? profileInfo?.data?.title_ar : profileInfo?.data?.title_en}
          </Typography.Paragraph>
          {role !== Role.PrimeMinister && (
            <Typography.Paragraph className={"user-location"}>
              {isArab ? profileInfo?.data?.country?.title_ar : profileInfo?.data?.country?.title_en}
            </Typography.Paragraph>
          )}
        </Col>
        <Col>
          <SvgIcon type={"arrowDown"} width={12} height={12} />
        </Col>
      </Flex>
    </Dropdown>
  );
};
